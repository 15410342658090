import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="grade"
export default class extends Controller {
  connect() {

    $(".filter-btn").click(function(){
      $(".organizer-grade").toggleClass("order-enable");
    });

    $('input').on('keyup', function() {
      $('.errors').text('')
    });
    $('.gradeForm').validate({
      rules: {
        "grade[name]": {
          required: true,
          minlength: 2,
          maxlength: 50,
          nameregex: true
        },
        "grade[description]": {
          required: true,
          minlength: 2,
          nameregex: true
        },
      },
      messages: {
      "grade[name]": {
        required: 'Grade name is required.',
        minlength: 'Must be 2-50 characters long',
        maxlength: 'Must be 2-50 characters long',
        nameregex: 'Contains alphanumeric and symbol only'
      },
      "grade[description]": {
        required: 'Description is required.',
        minlength: 'Must be 2-1000 characters long',
        maxlength: 'Must be 2-1000 characters long',
        nameregex: 'Contains alphanumeric and symbol only'
      }
    }});
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z0-9-=()., !@#$%^&*()_+\\\\/|{}\n<>?:";'[\]]+$/.test(value); 

    },'Contains alphanumeric and symbol only');
  }
}
