import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-deduction-rule"
export default class extends Controller {
  connect() {
    $(".employee-deduction").validate({
      rules: {
        "employee_deduction_rule[employee_contribution_rate]": {
          required: true,
          percentage_validator: true,
        },
        "employee_deduction_rule[employer_contribution_rate]": {
          required: true,
          percentage_validator: true,
        },
        "employee_deduction_rule[employee_contribution]": {
          required: true,
          number_regex: true,
          maxlength: 10
        },
        "employee_deduction_rule[employer_contribution]": {
          required: true,
          number_regex: true,
          maxlength: 10
        },
        "employee_deduction_rule[limit_amount]": {
          required: true,
          number_regex: true,
          maxlength: 10
        },
        "employee_deduction_rule[applied_on]": {
          required: true,
        }
      },
      messages: {
        "employee_deduction_rule[employee_contribution_rate]": {
          required:  'Please enter employee contribution rate',
        },
        "employee_deduction_rule[employer_contribution_rate]": {
          required:  'Please enter employer contribution rate',
        },
        "employee_deduction_rule[employee_contribution]": {
          required:  'Please enter employee contribution',
          maxlength: 'only 10 digits are allowed'
        },
        "employee_deduction_rule[employer_contribution]": {
          required:  'Please enter employer contribution',
          maxlength: 'only 10 digits are allowed'
        },
        "employee_deduction_rule[limit_amount]": {
          required:  'Please enter limit amount',
          maxlength: 'only 10 digits are allowed'
        },
        "employee_deduction_rule[applied_on]": {
          required:  'Please select applied on',
        }
      }
    });

    $.validator.addMethod('number_regex_decimal', function(value, element) {
      return this.optional(element) || /^[\d,]+(\.\d+)?$/.test(value);
    }, 'Only digits, commas, and decimals are allowed');

    $.validator.addMethod('percentage_validator', function(value, element) {
      return this.optional(element) || /^(?:\d{1,2}(?:\.\d{1,2})?)?$/.test(value);
    }, 'Values upto 2 decimal places are allowed');

    customSelect2('#employee_deduction_rule_applied_on', true);

    function updateFieldsVisibility(deduction_rule) {
      $('.employee-contribution, .professional-tax, .deduction-cycle, .pf-limit-amount, .pf-celling-limit, .employee-contribution-rate, .deduction-applied-on').hide();
      if (deduction_rule === 'Epf') {
        $('.pf-celling-limit, .employee-contribution-rate, .deduction-applied-on').show();
      } else if (deduction_rule == 'Esic') {
        console.log(deduction_rule);
        $('.employee-contribution-rate, .deduction-applied-on').show();
      } else if (deduction_rule == 'Professional Tax') {
        console.log(deduction_rule);
        $('.professional-tax, .deduction-cycle, .deduction-applied-on').show();
      } else if (deduction_rule == 'Labour Welfare Fund') {
        console.log(deduction_rule);
        $('.employee-contribution, .deduction-cycle, .deduction-status').show();
      }
    }

    $(document).ready(function() {
      var selectedDeductionRule = $('#organizer_deduction_rule').val();
      updateFieldsVisibility(selectedDeductionRule);
      console.log(selectedDeductionRule);

      if ($('#employee_deduction_rule_pf_celling_limit').is(':checked')) {
        $('.deduction-applied-on').hide();
        $('.pf-limit-amount').show();
      }
      $('#employee_deduction_rule_pf_celling_limit').change(function() {
        if ($(this).is(':checked')) {
          $('.pf-limit-amount').show();
          $('.deduction-applied-on').hide();
        } else {
          $('.pf-limit-amount').hide();
          $('.deduction-applied-on').show();
        }
      });
    });
  }
}
