import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="staff-turnover"
export default class extends Controller {
  connect() {

    // customSelect2('#staff_turnover', true, 'Year');
    $(document).ready(function() {
      $('#staff_turnover').change(function() {
        var staffTurnover = $(this).val();
        $.ajax({
          url: '/organizers/dashboard/staff_turnovers',
          method: 'GET',
          data: { year: staffTurnover },
          success: function(response) {
            var left_employee = JSON.parse(response.left_employee);
            var new_employee = JSON.parse(response.new_employee);
            var all_employee = JSON.parse(response.all_employee);
            myChart(left_employee, new_employee, all_employee)
          },
          error: function(error) {
            console.error('Error:', error);
          }
        });
      });
      const left_employee = JSON.parse($('#left_employee_turnover').val());
      const new_employee = JSON.parse($('#new_employee_turnover').val());
      const all_employee = JSON.parse($('#all_employee_turnover').val());
      myChart(left_employee, new_employee, all_employee)
    });
    function myChart(left_employee, new_employee, all_employee){
      console.log(left_employee, new_employee, all_employee)
      console.log('start')
      var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      console.log(left_employee, new_employee, all_employee)
      Highcharts.chart('staff_turnover_dashboard', {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },

        xAxis: {
          categories: monthNames,
          crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Staff Turnover'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: 'Total',
            color: '#58508d',
            data: all_employee

        }, {
            name: 'New',
            color: '#ffa660',
            data: new_employee

        }, {
            name: 'Left',
            color: '#ff6361',
            data: left_employee

        }]
      });
    }
  }
}
