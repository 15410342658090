import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="monthly-late-report"
export default class extends Controller {
  connect() {

    customSelect2('#search_employee', true, 'Please Select', true);
    customSelect2('#time_period', true, 'Please Select', true);
    document.addEventListener('turbo:load', () => {
      this.initializeDatatable();
    });
    document.addEventListener('refreshMonthlyLateDataTable', (event) => {
      console.log('------------------------')
      this.refreshMonthlyLateDataTable(event.detail);
    });

    $('#search_employee, #time_period').change(function() {
      var search_employee = $('#search_employee').val();
      var time_period = $('#time_period').val();
      $.ajax({
        url: $('#monthly-late-report-datatable').data('source'),
        method: 'GET',
        data: { search_employee: search_employee,
                time_period: time_period },
        success: function(response) {
        }
      });
    });
  }

  initializeDatatable() {
    let table_id = $('.table-id-element').attr('id')
    if (!$.fn.DataTable.isDataTable('#'+ table_id)) {
      console.log('checking the load')
      $('#'+ table_id).dataTable({
        "orderCellsTop": true,
        "processing": true,
        "pagingType": "simple_numbers",
        "oLanguage": {
          "sLengthMenu": "Show Entries _MENU_",
          "sProcessing": "<div class='ld_ind_wrp'></div>",
          "sSearch": "",
          "oPaginate": {
            "sNext": '<i class="bi bi-chevron-right"></i>',
            "sPrevious": '<i class="bi bi-chevron-left"></i>'
          },
        },
        "dom": "<'row custom_data_filter_col'<'col-sm-12 custom_datatable'f>>" +
          "<'row'<'col-sm-12 table-responsive'tr>>" +
        "<'row mgltm0'<'col-sm-5 show-entry'li><'col-sm-7'p>>"+ "<'row'<'col-sm-12'B>>" ,
          'buttons': [
            {
              extend: 'csv',
              text: 'Export CSV',
            },
            {
              extend: 'excel',
              text: 'Export Excel',
            }
          ],
      });
    }
  }

  refreshMonthlyLateDataTable(detail) {
    console.log('in refresh')
    let table_id = detail.table_id
    if ($.fn.DataTable.isDataTable('#' + table_id)) {
      $('#' + table_id).DataTable().destroy();
    }
    this.initializeDatatable();
  }
}
