import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report"
export default class extends Controller {
  connect() {

    $(".filter-btn").click(function(){
      $(".organizer-payroll-breakup-report").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".organizer-ratio-report").toggleClass("order-enable");
    });

     $(".filter-btn").click(function(){
      $(".organizer-loan-report").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".organizer-candidate-referral-report").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".organizer-candidate-interview-report").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".organizer-earnings-deduction").toggleClass("order-enable");
    });    

    $(".filter-btn").click(function(){
      $(".organizer-invoice-report").toggleClass("order-enable");
    });

    $('#head_count_from_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#head_count_to_date').datepicker('setStartDate', null);        
        } else if (selected.date != undefined) {
          var minDate = new Date($("#head_count_from_date").datepicker("getDate"));
          $('#head_count_to_date').datepicker('setStartDate', minDate);
        }
      });

    $('#head_count_to_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#head_count_from_date').datepicker('setEndDate', new Date());        
        } else if (selected.date != undefined) {
          var maxDate = new Date($("#head_count_to_date").datepicker("getDate"));
          $('#head_count_from_date').datepicker('setEndDate', maxDate);
        }
      });

    var initialFromDate = $('#head_count_from_date').datepicker('getDate');
    if (initialFromDate != null){
      $('#head_count_to_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#head_count_to_date').datepicker('getDate');
    if (initialToDate != null){
      $('#head_count_from_date').datepicker('setEndDate', initialToDate);
    }

    $('#interview_from_date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#interview_to_date').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date($("#interview_from_date").datepicker("getDate"));
          $('#interview_to_date').datepicker('setStartDate', minDate);
        }
      });
    
    $('#interview_to_date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#interview_from_date').datepicker('setEndDate', null);
        } else if (selected.date != undefined) {
          var maxDate = new Date($("#interview_to_date").datepicker("getDate"));
          $('#interview_from_date').datepicker('setEndDate', maxDate);
        }
      });
    $('#head_count_to_date').datepicker('setStartDate',  $('#head_count_from_date').datepicker('getDate'));
    $('#head_count_from_date').datepicker('setEndDate', $('#head_count_to_date').datepicker('getDate'));


    $('#work_hour_from_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#work_hour_to_date').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date($("#work_hour_from_date").datepicker("getDate"));
          $('#work_hour_to_date').datepicker('setStartDate', minDate);
        }
      });

    $('#work_hour_to_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#work_hour_from_date').datepicker('setEndDate', new Date());        
        } else if (selected.date != undefined) {
          var maxDate = new Date($("#work_hour_to_date").datepicker("getDate"));
          $('#work_hour_from_date').datepicker('setEndDate', maxDate);
        }
      });

    var workHourFromDate = $('#work_hour_from_date').datepicker('getDate');
    if (workHourFromDate != null){
      $('#work_hour_to_date').datepicker('setStartDate', workHourFromDate);
    }

    var workHourToDate = $('#work_hour_to_date').datepicker('getDate');
    if (workHourToDate != null){
      $('#work_hour_from_date').datepicker('setEndDate', workHourToDate);
    }

    $('#status-search-from').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#status-search-to').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date($("#status-search-from").datepicker("getDate"));
          $('#status-search-to').datepicker('setStartDate', minDate);
        }
      });

    $('#status-search-to').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#status-search-from').datepicker('setEndDate', new Date());        
        } else if (selected.date != undefined) {
          var maxDate = new Date($("#status-search-to").datepicker("getDate"));
          $('#status-search-from').datepicker('setEndDate', maxDate);
        }
      });

    var workHourFromDate = $('#status-search-from').datepicker('getDate');
    if (workHourFromDate != null){
      $('#status-search-to').datepicker('setStartDate', workHourFromDate);
    }

    var workHourToDate = $('#status-search-to').datepicker('getDate');
    if (workHourToDate != null){
      $('#status-search-from').datepicker('setEndDate', workHourToDate);
    }

    $('#invoice_from_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#invoice_to_date').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date($("#invoice_from_date").datepicker("getDate"));
          $('#invoice_to_date').datepicker('setStartDate', minDate);
        }
      });

    $('#invoice_to_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#invoice_from_date').datepicker('setEndDate', new Date());
        } else if (selected.date != undefined) {
          var maxDate = new Date($("#invoice_to_date").datepicker("getDate"));
          $('#invoice_from_date').datepicker('setEndDate', maxDate);
        }
      });
    var workHourFromDate = $('#invoice_from_date').datepicker('getDate');
    if (workHourFromDate != null){
      $('#invoice_to_date').datepicker('setStartDate', workHourFromDate);
    }

    var workHourToDate = $('#invoice_to_date').datepicker('getDate');
    if (workHourToDate != null){
      $('#invoice_from_date').datepicker('setEndDate', workHourToDate);
    }
  }

  toggleAction(){
    event.preventDefault();
    let div = document.getElementById('sub-section')

    if (div.classList.contains('d-none')){
      div.classList.remove("d-none");
    }
    else{
      div.classList.add("d-none");
    }
  }

  payrollAction(){
    event.preventDefault();
    let div = document.getElementById('payroll-section')

    if (div.classList.contains('d-none')){
      div.classList.remove("d-none");
    }
    else{
      div.classList.add("d-none");
    }
  }

  hiringAction(){
    event.preventDefault();
    let div = document.getElementById('hiring-section')
    if (div.classList.contains('d-none')){
      div.classList.remove("d-none");
    }
    else{
      div.classList.add("d-none");
    }
  }
  reimbursementAction(){
    event.preventDefault();
    let div = document.getElementById('reimbursement-section')
    if (div.classList.contains('d-none')){
      div.classList.remove("d-none");
    }
    else{
      div.classList.add("d-none");
    }
  }

  attendanceAction(){
    event.preventDefault();
    let div = document.getElementById('attendance-section')
    if (div.classList.contains('d-none')){
      div.classList.remove("d-none");
    }
    else{
      div.classList.add("d-none");
    }
  }

  toggleInvoice(){
    event.preventDefault();
    let div = document.getElementById('invoice-section')

    if (div.classList.contains('d-none')){
      div.classList.remove("d-none");
    }
    else{
      div.classList.add("d-none");
    }
  }

  toggleDailyStatus(){
    event.preventDefault();
    let div = document.getElementById('daily-status-section')

    if (div.classList.contains('d-none')){
      div.classList.remove("d-none");
    }
    else{
      div.classList.add("d-none");
    }
  }
}
