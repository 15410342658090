import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="referral"
export default class extends Controller {
  connect() {
    customSelect2('#status', true);
    customSelect2('#referral_country_code', true);

    $('#pay_month').datepicker({
      format: 'MM yyyy',  // Format to show the month name and year
      startView: "months",  // Start view mode as months
      minViewMode: "months",
      startDate: '2M',  // Min view mode to months
      autoclose: true,
    })
    
    $(".filter-btn").click(function(){
      $(".organizer-referral").toggleClass("order-enable");
    });
    
    $(".referral-form").validate({
      rules: {
        "referral[job_opening_id]": {
          required: true,
        },
        "referral[candidate_name]": {
          required: true,
          minlength: 2,
          maxlength: 30,
          nameregex: true
        },
        "referral[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "referral[contact_number]": {
          number: true,
          minlength: 10,
          maxlength: 10,
          required: true
        },
        "referral[year_of_experiance]": {
          required: true,
          min: 0,
          max: 99,
          number_regex_decimal: true
        }
      },
      messages: {
        "referral[job_opening_id]": {
          required: 'Please select job openings.',
        },
        "referral[candidate_name]": {
          required: 'Please enter candidate name.',
          minlength: 'Please enter atleast 2 characters',
          maxlength: 'Maximum lenth 30 characters.',
          nameregex: 'only alphabets are allowed'
        },
        "referral[email]": {
          required: 'Please enter email.',
          email: 'Email pattern must followed.',
          emailregex: 'Email must only contains @ and . as a special character'
        },
        "referral[contact_number]": {
          number: 'Only numbers are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
          required: 'Please enter contact number.',
        },
        "referral[year_of_experiance]": {
          required: 'Please enter year of experience.',
          min: 'Please enter valid year of experience.',
          max: 'Please enter valid year of experience.',
          number: 'Only numbers are allowed.',
        },
        "referral[resume_file]": {
          required: 'Please upload resume file.',
        }
      }
    });

    $(".referral_payment_form").validate({
      rules: {
        "referral[amount]": {
          required: true,
          min: 1,
          maxlength: 10,
          number_regex_decimal: true,
        },
        'referral[pay_month]': {
          required: true
        }
      },
      messages: {
        "referral[amount]": {
          required: 'Please enter amount.',
          min: 'Please enter amount.',
          maxlength: 'Only 10 digits are allowed.',
        },
        'referral[pay_month]': {
          required: 'Please select payment month.'
        }
      }
    });

    function restrictInputLength(element, maxLength) {
      $(element).on('keydown', function(event) {
        if (event.key == 'Backspace' || event.key == 'Delete') {
          return;
        } else {
          if (maxLength == 10 && !/^\d$/.test(event.key)) {
            event.preventDefault();
          }
          if ($(this).val().length === maxLength) {
            event.preventDefault();
          }
        }
      });
    }

    restrictInputLength('#referral_year_of_experiance', 5);
    restrictInputLength('#referral_contact_number', 10);
    

    $.validator.addMethod('number_regex_decimal', function(value, element) {
      return this.optional(element) || /^[\d]+(?:\.\d+)?$/.test(value);
    }, 'Only digits allowed');

    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /\d{10}\b/g.test(value);
    },'Contains digits only');
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    });
    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^\b[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
    },'Contains alphabets and number only.');
  }
}
