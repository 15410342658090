import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customer"
export default class extends Controller {
  connect() {
    customSelect2('#customer_country_code', true);

    $(".CustomerForm").validate({
      rules: {
        "customer[email]": {
          required: true,
          emailregex: true
        },
        "customer[name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 30
        },
        "customer[mobile_number]": {
          required: true,
          phoneregex: true,
          minlength: 10,
          maxlength: 10,
          min: 1000000000
        },
        "customer[city]": {
          nameregex: true,
          required: true,
          minlength: 2,
          maxlength: 30
        },
        "customer[state]": {
          required: true,
          nameregex: true
        },
        "customer[country]": {
          required: true
        },
        "customer[address]": {
          required: true,
          minlength: 2,
          maxlength: 50
        }
      },
      messages: {
        "customer[email]": {
          required: 'Please enter email.',
          emailregex: 'Please enter a valid email address'
        },
        "customer[name]": {
          required: 'Please enter name.',
          nameregex: 'Allow only alphabets',
          minlength: 'Name must be 2-30 characters long',
          maxlength: 'Name must be 2-30 characters long'
        },
        "customer[mobile_number]": {
          required: 'Please enter contact number.',
          phoneregex: 'Only digits are allowed',
          maxlength: 'Contact number must be 10 digits long',
          min: 'Please enter correct number.'
        },
        "customer[city]": {
          required: 'Please enter city.',
          nameregex: 'Allow only alphabets',
          minlength: 'Please enter atleast 2 character',
          maxlength: 'City name must be 2-30 character long'
        },
        "customer[state]": {
          required: 'Please enter state.',
          nameregex: 'Allow only alphabets'
        },
        "customer[country]": {
          required: 'Please Select country.',
        },
        "customer[address]": {
          required: 'Please enter address.',
          minlength: 'Address must be 2-50 character long.',
          maxlength: 'Address must be 2-50 character long.'
        }
      }
    });
    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /^[0-9]+$/.test(value);
    },'Contains digits only');

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
    });
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    });
  }
}
