import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="export"
export default class extends Controller {
  connect() {
    console.log('ok')
    $(document).ready(function() {
      $("input[type='submit']").prop("disabled", true);

      if ($("input[type='checkbox']:checked").length > 0) {
        $("input[type='submit']").prop("disabled", false);
      }

      $("input[type='checkbox']").change(function() {
        if ($("input[type='checkbox']:checked").length > 0) {
          $("input[type='submit']").prop("disabled", false);
        } else {
          $("input[type='submit']").prop("disabled", true);
        }
      });
    });
  }
}
