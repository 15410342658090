import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="appraisal"
export default class extends Controller {
  connect() {
    customSelect2('#appraisal_employee_id', true, 'Please Select', true);
    $(".filter-btn").click(function(){
      $(".organizer-appraisal").toggleClass("order-enable");
      $(".organizer-appraisal-template").toggleClass("order-enable");
    });
    $(".AppraisalForm").validate({
      rules: {
        "appraisal_request[employee_id]": {
          required: true,
        },
        "appraisal_request[last_appraisal_date]": {
          required: true
        },
        "appraisal_request[current_ctc]": {
          required: true,
          minlength: 4,
          maxlength: 12,
          after_decimal: true
        },
        "appraisal_request[increased_ctc]": {
          minlength: 2,
          maxlength: 12,
          after_decimal: true
        },
        "appraisal_request[status]": {
          required: true
        },
      },
      messages: {
        "appraisal_request[employee_id]": {
          required: "Please select employee name."
        },
        "appraisal_request[last_appraisal_date]": {
          required: "Please enter last appraisal."
        },
        "appraisal_request[current_ctc]": {
          required: 'Please enter current ctc.',
          minlength: 'Please enter atleast 4 digits.',
          maxlength: 'Maximum 12 digit are allowed.'
        },
        "appraisal_request[increased_ctc]": {
          minlength: 'Please enter atleast 2 digits.',
          maxlength: 'Only 12 digit are allowed',
        },
        "appraisal_request[status]": {
          required: 'Please select status.'
        },
      }
    });

    $(".re-eval").validate({
      rules: {
        "appraisal_request[reevaluation_description]": {
          required: true,
          minlength: 2
        },
      },
      messages: {
        "appraisal_request[reevaluation_description]": {
          required: "This field is reuired."
        },
      }
    });
    $(".cancel-form").validate({
      rules: {
        "appraisal_request[cancel_description]": {
          required: true,
          minlength: 2
        },
      },
      messages: {
        "appraisal_request[cancel_description]": {
          required: "This field is reuired."
        },
      },
      submitHandler: function(form) {
        console.log('fkjsdhfkjhsd iroiwueroiwr djfhskjdfhkjsdf aldjaksklsajsdlk')
        var $link = $(this);
        event.preventDefault();

        var iconType = $link.data('icon') || 'warning';
        var confirmMessage = $link.data('confirm-message') || 'Are you sure, you want to cancel appraisal?';
        var confirmText = $link.data('confirm-text') || 'OK';
        var cancelText = $link.data('cancel-text') || 'Cancel';
        var htmlContent = $link.data('icon') == 'warning2' ? '<img src="https://img.icons8.com/?size=256&id=1lpBESDEGeuX&format=png" class="swal2-custom-icon"/>' : '';
        htmlContent = htmlContent + `<div class="swal2-html-container">${confirmMessage}</div>`;

        // SweetAlert confirmation dialog
        Swal.fire({
          icon: iconType,
          html: htmlContent,
          showCancelButton: true,
          confirmButtonColor: '#d11a2a',
          confirmButtonText: confirmText,
          cancelButtonText: cancelText
        }).then(function(result) {
          if (result.isConfirmed) {
            form.submit();
          }
        });
      }
    });
    $.validator.addMethod('number_regex', function(value, element) {
      return this.optional(element) || /^[\d,]+$/.test(value);
    }, 'Only digits and commas are allowed');

    $.validator.addMethod('after_decimal', function(value, element) {
      return this.optional(element) || /^(\d+(,\d+)*|\d+)(\.\d{1,2})?$/.test(value);
    }, 'Please enter valid number also only two numbers allowed after decimal.');

    $('#appraisal_request_last_appraisal_date').datepicker({
        format: 'dd/mm/yyyy',
        endDate: '+0d',
        autoclose: true
    })
    $('#appraisal_request_next_appraisal_date').datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true
    })
    $('.datepicker').datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true
    })

    $(".filter-btn").click(function(){
      $(".employee-my-appraisal").toggleClass("order-enable");
      $(".employee-team-appraisal").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".employee-my-appraisal").toggleClass("order-enable");
      $(".employee-team-appraisal").toggleClass("order-enable");
    });

    function togglePayHikeSection() {
      let pay_hike_show = ['completed', 're_evaluation'].includes($('#appraisal_request_status').val());
      $('#pay-hike-section').css('display', pay_hike_show ? 'flex' : 'none');
    }

    function toggleCTCFields() {
      let selected_pay_status = ['completed', 're_evaluation'].includes($('#appraisal_request_status').val());
      const isPercentage = $('#appraisal_request_pay_hike_type_percentage').is(':checked');
      $('.percentage_field').toggleClass('d-none', !isPercentage)
      $('#appraisal_request_increased_ctc').toggle(!(isPercentage || !selected_pay_status));
      $('#increased_ctc2').toggle(isPercentage || !selected_pay_status);
    }

    function updateCTC() {
      let current_value = parseFloat($('#appraisal_request_current_ctc').val()) || 0;
      let percent = parseFloat($('#appraisal_request_percentage').val()) || 0;
      let increased_ctc = current_value + ((current_value / 100) * percent);
      $('#appraisal_request_increased_ctc, #increased_ctc2').val(increased_ctc);
    }

    $('#is_direct').on('change', function(){
      const isChecked = $(this).is(':checked');
      if (isChecked) {
        $('#next_appraisal_date_section').hide();
      } else {
        $('#next_appraisal_date_section').show();
      }
    })

    $('#appraisal_request_pay_hike_type_fixed, #appraisal_request_pay_hike_type_percentage').on("change", toggleCTCFields).trigger('change');

    $('#appraisal_request_status').on('change', function() {
      togglePayHikeSection();
      $('#appraisal_request_pay_hike_type_fixed').trigger('change');
    }).trigger('change');

    $('#appraisal_request_percentage').on("input", updateCTC);

    $('#appraisal_status').on('change', function(){
      var selectedStatus = $(this).val();
      const apraisalIncreasedCtc = $('#appraisal_increased_ctc')
      if(selectedStatus == 'initial'){
        apraisalIncreasedCtc.prop('required', false).prop('disabled', true);
        $('.increased_star').hide();
      }else{
        apraisalIncreasedCtc.prop('required', true).prop('disabled', false);
        $('.increased_star').show();
      }
    })

  }
}
