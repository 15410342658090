import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="leave"
export default class extends Controller {
  connect() {

    $(document).on('click', '.swal-delete', function(event) {
      var $link = $(this);
      event.preventDefault();

      Swal.fire({
        icon: 'warning',
        html: '<img src="https://img.icons8.com/?size=256&id=1lpBESDEGeuX&format=png" class="swal2-custom-icon" /><h2 class="swal2-title">Are you sure?</h2><div class="swal2-html-container">You will not be able to recover this.</div>',
        showCancelButton: true,
        confirmButtonColor: '#d11a2a',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        customClass: 'delete-popup',
      }).then(function(result) {
        if (result.isConfirmed) {
          $.ajax({
            url: $link.attr('href'),
            type: 'DELETE',
            headers: {
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
              $link.closest('.comment-row').remove();
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your item has been deleted.',
                timer: 1500,
                showConfirmButton: false
              });
            },
            error: function(xhr, status, error) {
              console.error('Delete failed:', error);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                timer: 1500,
                showConfirmButton: false
              });
            }
          });
        }
      });
    });
    $(".filter-btn").click(function(){
      $(".employee-leave-request").toggleClass("order-enable");
    });

    console.log('test leave')

    $(".filter-btn").click(function(){
      console.log('org leave')
      $(".leave-request").toggleClass("order-enable");
    });
  
    $('.csv_import_csv_file_browse').click(function() {
      $('#csv_import_csv_file').click();
    });
    customSelect2('#type_category', true);
  }
}
