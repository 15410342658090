import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="setup-organization--roles"
export default class extends Controller {
  connect() {
    $('#setup-roles').validate();
    $('#setup-roles .role_name').each(function(index, el) {
      $(el).rules("add", {
        required: true,
        messages: {
          required: "Name is required",
        }
      });
    });
    $('#nested-fields').on('cocoon:after-insert', function(e, insertedItem) {
      $(this).append(insertedItem);
      var input = $(insertedItem).find('input');
      input.rules("add", {
        required: true,
        messages: {
          required: "Name is required",
        }
      });
    })
  }
}
