import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="salary-rule"
export default class extends Controller {
  connect() {

    $(".modal").on("shown.bs.modal", function()  { 
      var urlReplace = "#" // make the hash the id of the modal shown
      history.pushState(null, null, urlReplace); // push state that hash into the url
    });

    $(".filter-btn").click(function(){
      $(".organizer-earning").toggleClass("order-enable");
    });

    // If a pushstate has previously happened and the back button is clicked, hide any modals.
    $(window).on('popstate', function() { 
      console.log('popstate')
      $(".modal").hide()
      $('.modal-backdrop').remove()
    });

    $(".SalaryRule").validate({
      rules: {
        "salary_rule[salary_rule_name]": {
          required: true,
        },
        "salary_rule[applied_on]": {
          required: true,
        },
        "salary_rule[calculate_by_percent]": {
          required: true,
          percentage_validator: true,
        },
        "salary_rule[calculate_by_amount]": {
          required: true,
          number_regex: true,
          maxlength: 10
        }
      },
      messages: {
        "salary_rule[salary_rule_name]": {
          required: 'Please select salary rule.',
        },
        "salary_rule[applied_on]": {
          required:  'Please select applied on',
        },
        "salary_rule[calculate_by_percent]": {
          required:  'Please enter percent',
        },
        "salary_rule[calculate_by_amount]": {
          required:  'Please enter amaount',
          maxlength: 'only 10 digits are allowed'
        }
      }
    });

    $.validator.addMethod('number_regex', function(value, element) {
     return this.optional(element) || /^[\d,]+$/.test(value);
    }, 'Only digits and commas are allowed');

    $.validator.addMethod('percentage_validator', function(value, element) {
      return this.optional(element) || /^(?:\d{1,2}(?:\.\d{1,2})?)?$/.test(value);
    }, 'Values upto 2 decimal places are allowed');

    customSelect2('#organizer_salary_rule', true);
    customSelect2('#salary_rule_applied_on', true);

    $('.salary-calculate-amount').hide();
    $('.salary-calculate-percent').show();
    if ($('#salary_rule_calculation_type_flat').is(':checked')) {
      $('.salary-applied-on').hide();
      $('.salary-calculate-percent').hide();
      $('.salary-calculate-amount').show();
    }

    if ($('#salary_rule_calculation_type_additional').is(':checked')) {
      $('.salary-applied-on').show();
      $('.salary-calculate-percent').hide();
      $('.salary-calculate-amount').hide();
    }

    $('#salary_rule_calculation_type_percentage').change(function(){
      $('.salary-calculate-amount').hide()
      $('.salary-applied-on').show()
      $('.salary-calculate-percent').show()
    });

    $('#salary_rule_calculation_type_flat').change(function(){
      $('.salary-calculate-percent').hide()
      $('.salary-applied-on').hide()
      $('.salary-calculate-amount').show()
    });

    $('#salary_rule_calculation_type_additional').change(function(){
      $('.salary-applied-on').show();
      $('.salary-calculate-percent').hide();
      $('.salary-calculate-amount').hide();
    });

    $('#organizer_salary_rule').change(function(){
      if( $('#organizer_salary_rule').val() == 'gross_salary' || $('#organizer_salary_rule').val() == 'special_allowance') {
        $('#salary_rule_calculation_type_additional').prop('checked',true);
        $('.additional_calculation_type').show();
        $('.salary-applied-on').show();
        $('.salary-calculate-percent').hide();
        $('.salary-calculate-amount').hide();
      }else{
        $('.additional_calculation_type').hide();
        $('.salary-applied-on').show();
        $('.salary-calculate-percent').hide();
        $('.salary-calculate-amount').hide();
      }
    });
  }
}
