import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="policies"
export default class extends Controller {
  connect() {
    $('#policy_apply_start_date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true
    })

    $(".filter-btn").click(function(){
      $(".employee-policy").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".organizer-policy").toggleClass("order-enable");
    });


    $('#policy_year').datepicker({
      format: "yyyy",
      viewMode: "years",
      minViewMode: "years",
      autoclose: true
    }).on('changeDate input', function (selected) {
        let financialYearMonth = $('#financial-year').text() == 'Apr-Mar' ? 3 : 0;
        var selectedDate = new Date(selected.date);
        var date = new Date(selectedDate.getFullYear(), financialYearMonth, 1)
        console.log('Date: ' + date)
        if (selected.date != undefined) {
          updateStartEndDate(date);
        }
      });

    updateStartEndDate(new Date());
    function updateStartEndDate(selectedDate) {
      let financialYear = $('#financial-year').text() == 'Apr-Mar' ? 4 : 1;
      let selectedYear = selectedDate.getFullYear();
      let startDateMonth = financialYear == 4 ? 3 : 0;
      if (selectedDate.getMonth() < startDateMonth) {
        selectedYear = selectedYear - 1
      }

      let endDateMonth = financialYear == 4 ? 2 : 11;
      let startDate = new Date(selectedYear, startDateMonth, 1);
      let endDate = new Date(selectedYear + 1, endDateMonth + 1, 0);

      $('#policy_apply_start_date').datepicker('setStartDate', startDate);
      $('#policy_apply_start_date').datepicker('setEndDate', endDate);
      console.log(selectedDate +' and '+ (new Date()))
      if (selectedDate != (new Date())){
        $('#policy_apply_start_date').datepicker('setDate', startDate);
      }
    }

    $('#datepicker').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    })

    $("#policy_from_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#policy_to_date').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date(selected.date.valueOf());
          $('#policy_to_date').datepicker('setStartDate', minDate);
        }
      })

    $("#policy_to_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#policy_from_date').datepicker('setEndDate', null);
        } else if (selected.date != undefined) {
          var maxDate = new Date(selected.date.valueOf());
          $('#policy_from_date').datepicker('setEndDate', maxDate);
        }
      })
    $('#policy_to_date').datepicker('setStartDate', $('#policy_from_date').datepicker('getDate'));
    $('#policy_from_date').datepicker('setEndDate', $('#policy_to_date').datepicker('getDate'));

    $(".PolicyFrom").validate({
      rules: {
        "policy[policy_name]": {
          required: true
        },
        "policy[apply_start_date]": {
          required: true
        },
        "policy[apply_end_date]": {
          required: true
        },
        "policy[year]": {
          required: true
        },
        "policy[file]":{
          required: true,
          pdf: true
        }
      },
      messages: {
        "policy[file]": {
          required: "Please select policy document."
        },
        "policy[policy_name]": {
          required: "Please enter policy name."
        },
        "policy[apply_start_date]": {
          required: "Please select effective date."
        },
        "policy[year]": {
          required: "Please select financial year."
        },
        "policy[apply_end_date]": {
          required: "Please select date."
        }
      }
    });
    $(".PolicyEditFrom").validate({
      rules: {  
        "policy[policy_name]": {
          required: true
        },
        "policy[apply_start_date]": {
          required: true
        },
        "policy[apply_end_date]": {
          required: true
        },
        "policy[file]":{
          pdf: true
        }
      },
      messages: {
        "policy[policy_name]": {
          required: "Please enter policy name."
        },
        "policy[apply_start_date]": {
          required: "Please select effective date."
        },
        "policy[apply_end_date]": {
          required: "Please select date."
        }
      }
    });

    $.validator.addMethod("pdf", function(value, element) {
      return this.optional(element) || /\.(pdf)$/i.test(value);
    }, "Please select a PDF file.");

  }
}
