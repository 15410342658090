import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="deduction-professional-tax"
export default class extends Controller {
  connect() {
    $('#deduction-tax-range').find(".nested-fields").each(function(index, element) {
      console.log('helow')
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('start-range')){
            $(input).rules( "add", {
              required: true,
              maxlength: 10,
              number_regex: true,
              messages: {
                required: "Please enter start range",
                maxlength: 'Maximum 10 digits are allowed.',
              }
            });
          }else if ($(input).attr('class').includes('end-range')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 10,
              number_regex: true,
              messages: {
                required: "Please enter end range",
                maxlength: 'Maximum 10 digits are allowed.'
              }
            });
          }else if ($(input).attr('class').includes('tax-amount')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 10,
              number_regex: true,
              messages: {
                required: "Please enter tax amount",
                maxlength: 'Maximum 10 digits are allowed.'
              }
            });
          }
        }
      })
    })

    $.validator.addMethod('number_regex', function(value, element) {
     return this.optional(element) || /^[\d,]+$/.test(value);
    }, 'Only digits and commas are allowed');
  }
}

