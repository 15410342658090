import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-termination"
export default class extends Controller {
  connect() {
    console.log('testing')
    customSelect2('#employee_separation_employee_id', false);

    $(".filter-btn").click(function(){
      $(".organizer-separation").toggleClass("order-enable");
      $(".organizer-termination").toggleClass("order-enable");
    });
    $('.EmployeeTerminationFrom').validate({
      rules: {
        'employee_separation[title]': {
          required: true,
          minlength: 3,
          maxlength: 50
        },
        'employee_separation[reason]': {
          required: true
        }
      },
      messages: {
        'employee_separation[title]': {
          required: 'Please enter title.',
        },
        'employee_separation[reason]': {
          required: 'please enter reason.',
        }
      },
      errorElement: "small"
    });
  }
}
