import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reimbursment"
export default class extends Controller {
  connect() {
    // customSelect2('#status', true, 'All')
    $(".filter-btn").click(function(){
      $(".employee-reimbursement").toggleClass("order-enable");
    });
    $(".filter-btn").click(function(){
      $(".organizer-expense-report").toggleClass("order-enable");
    });
    customSelect2('#status', true);

    $(".reimbursments").validate({
      rules: {
        "reimbursment[title]": {
          required: true,
          minlength: 2,
          maxlength: 60,
          nameregex: true
        },
        "reimbursment[date]": {
          required: true
        },
        "reimbursment[amount]": {
          required: true,
          number_regex_decimal: true,
          maxlength: 14,
          minlength: 3
        },
        "reimbursment[description]": {
          required: true,
          minlength: 10
        }
      },
      messages: {
        "reimbursment[title]": {
          required: 'Title is required.',
          minlength: 'Title should be between 2-60 character.',
          minlength: 'Title should be between 2-60 character.',
          nameregex: 'only alphabets are allowed'
        },
        "reimbursment[date]": {
          required:  'Date is required.'
        },
        "reimbursment[amount]": {
          required: 'Amount is required.',
          maxlength: 'Only 10 digits are allowed',
          minlength: 'Please enter minimum 3 digits'
        },
        "reimbursment[description]": {
          required: 'Description is required.',
          minlength: 'Please enter atleast 10 character.'
        }
      }
    });

    $(".reimbursment-reject-reason").validate({
      rules: {
        "reimbursment[reason]": {
          required: true,
          minlength: 10
        },
        "reimbursment[approx_paid_date]": {
          required: true
        }
      },
      messages: {
        "reimbursment[reason]": {
          required: 'Please enter comment.',
          minlength: 'Please enter atleast 10 character.'
        },
        "reimbursment[approx_paid_date]": {
          required: 'Please enter approx paid date.',
        }
      }
    });

    $.validator.addMethod('number_regex_decimal', function(value, element) {
      return this.optional(element) || /^[\d,]+(\.\d+)?$/.test(value);
    }, 'Only digits, commas, and decimals are allowed');
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    });

    $("#reimbursment_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: '+0d',
    });

    $("#reimbursment_approx_paid_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: '+1d',
    });

    $('#reimbursment_amount').on('input', function(event) {
      let value = event.target.value.replace(/[^\d.]/g, ''); // Remove non-numeric and non-decimal characters
      let [wholeNumber, decimal] = value.split('.');
      if (!wholeNumber && !decimal) {
        event.target.value = '';
        return;
      }
      wholeNumber = wholeNumber || '0';
      event.target.value = parseFloat(wholeNumber).toLocaleString('en-IN');
      if (decimal) {
        decimal = decimal.slice(0, 2);
        event.target.value = event.target.value + '.' + decimal;
      } else if (value.includes('.')) {
        event.target.value = event.target.value + '.';
      }
    });


    $(document).on('turbo:load', function() {
      $(".filter-btn").click(function(){
        $(".org-reimbursement").toggleClass("order-enable");
      });
    })

    // $('#reimbursment_amount').on('input', function(event) {
    //   let value = event.target.value.replace(/[^\d.]/g, '');
    //   if (value.includes('.')) {
    //     event.target.value = parseFloat(value).toLocaleString('en-IN', {
    //       minimumFractionDigits: 1,
    //       maximumFractionDigits: 2
    //     });
    //   } else {
    //     event.target.value = parseFloat(value).toLocaleString('en-IN');
    //   }
    // });
    // $(document).on('turbo:load', function() {
    //   $("#filter-btn").click(function(){
    //     $(".org-reimbursement").toggleClass("order-enable");
    //   });
    //   if (!$.fn.DataTable.isDataTable('#organizers-reimbursement-datatable')) {
    //     $('#organizers-reimbursement-datatable').dataTable({
    //       "oLanguage": {
    //         "sLengthMenu": "Show Entries _MENU_",
    //         "sProcessing": "<div class='ld_ind_wrp'></div>",
    //         "sSearch": "",
    //         "oPaginate": {
    //           "sNext": '<i class="bi bi-chevron-right"></i>',
    //           "sPrevious": '<i class="bi bi-chevron-left"></i>'
    //         },
    //       },
    //       "orderCellsTop": true,
    //       "processing": true,
    //       "serverSide": true,
    //       "bStateSave" : false,
    //       "fnInitComplete": function(oSettings, json) {
    //           var cols = oSettings.aoPreSearchCols;
    //           console.log(cols)
    //           for (var i = 0; i < cols.length; i++) {
    //             var value = cols[i].sSearch;
    //             console.log("value", value)
    //             if (value.length > 0 && value != "null") {
    //               $(".org-reimbursement").addClass("order-enable");
    //               selection = $('.filters input.default_input, .filters select.select2-multiple')
    //               data_type = selection[i].type
    //               if (data_type == "text") {
    //                 console.log(data_type)
    //                 selection[i].value = value;
    //               } else {
    //                 record_id = selection[i].id
    //                 console.log(record_id)
    //                 $("#"+record_id).val(value.split("|"))
    //                 $("#"+record_id).parent().find(".select2-search__field").val(value.split("|"))
    //               }
    //             }
    //           }
    //           return $('.filters input, .filters select', this).on('keyup change clear', (function(_this) {
    //             return function(e) {
    //               var th;
    //               var search_value;
    //               th = $(e.target).closest("th");
    //               var value = $(e.target).val();
    //               if (typeof value == "string") {
    //                 search_value = value
    //               } else {
    //                 if (value == null) {
    //                   search_value = ""
    //                 } else {
    //                   search_value = value.join("|")
    //                 }
    //               }
    //               console.log(search_value)
    //               return _this.api().column(th.index()).search(search_value).draw();
    //             };
    //           })(this));
    //         },
    //       "ajax": {
    //         "url": $('#organizers-reimbursement-datatable').data('source'),
    //        "dataSrc": function ( json ) {
    //           return json.data;
    //         },
    //         data: function (d) {
    //           return $.extend({}, d, {
    //             status: $('#status-filter').val(),
    //           });
    //         },
    //         error: function (xhr, error, thrown) {
    //           sessionExpiredErrorMessage(thrown)
    //         },
    //       },
    //       "pagingType": "simple_numbers",
    //       "columns": [
    //         {"data": "title"},
    //         {"data": "employee_id"},
    //         {"data": "date"},
    //         {"data": "status"},
    //         {"data": "action"}
    //       ],
    //       // "order": [[ 3, "desc" ]],
    //       "dom": "<'row custom_data_filter_col'<'col-sm-12 custom_datatable'f>>" +
    //         "<'row'<'col-sm-12 table-responsive'tr>>" +
    //       "<'row mgltm0'<'col-sm-5 show-entry'li><'col-sm-7'p>>"+ "<'row'<'col-sm-12'B>>" ,
    //       'buttons': [
    //           {
    //               extend: 'csv',
    //               text: 'Export CSV',
    //               exportOptions: {
    //                   // columns: ':not(:eq(7))' // Exclude column 1 from CSV export
    //               }
    //           },
    //           {
    //               extend: 'excel',
    //               text: 'Export Excel',
    //               exportOptions: {
    //                   // columns: ':not(:eq(7))' // Exclude column 1 from Excel export
    //               }
    //           }
    //       ],
    //       "columnDefs": [
    //         { orderable: false, targets: -1, className: "select-checkbox"}
    //       ]
    //     });
        
    //     $('#datatable-custom-search').keyup(function() {
    //       $('.dataTables_wrapper .dataTables_filter input[type="search"]').val($(this).val());
    //       $('.dataTables_wrapper .dataTables_filter input[type="search"]').keyup()
    //     })
    //     $('.exel_report_btn').click(function() {
    //         $('.buttons-excel').click();
    //     });
    //     $('.csv_report_btn').click(function() {
    //         $('.buttons-csv').click();
    //     });
    //     $('.copy_report_btn').click(function() {
    //         $('.buttons-copy').click();
    //     });
    //     $('.pdf_report_btn').click(function() {
    //         $('.buttons-pdf').click();
    //     });
    //     customSelect2('.org-reimbursement-table #status', true, 'Please Select', true);

    //     $('#reimbursement-date').datepicker({
    //       format: 'yyyy-mm-dd',
    //       autoclose: true,
    //     });

    //     $('#csv_import_csv_file').change(function() {
    //       var filename = $(this).val().split('\\').pop(); // Get the filename without the full path
    //       $('#order-file-name').text(filename); // Update the content of the small tag with the filename
    //     });

    //     $('.csv_import_csv_file_browse').click(function() {
    //       $('#csv_import_csv_file').click();
    //     });
    //   } else {
    //     console.log('DataTable already initialized')
    //   }
    // });

  }
}
