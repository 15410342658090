import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-other-deduction"
export default class extends Controller {
  connect() {
    $(".other_deduction").validate({
      rules: {
        "other_deduction[deduction_amount]": {
          required: true,
          number_regex: true,
          maxlength: 10
        }
      },
      messages: {
        "other_deduction[deduction_amount]": {
          required:  'Please enter deduction amount',
        }
      }
    });
  }
}
