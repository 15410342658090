import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard--section"
export default class extends Controller {
  connect() {
  }

  filterByTime() {
    let form = document.getElementById('dashboard-search-form')
    form.submit();
  }
}
