import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="work-log"
export default class extends Controller {
  connect() {
    $(".datepicker").attr("autocomplete", "off"); 
    $('#work_log_date').datepicker({
      format: 'dd/mm/yyyy',
      startDate: '-2d',
      endDate: '+0d',
      autoclose: true
    })
    $("#new_work_log").validate({
      rules: {
        "work_log[log]": {
          required: true,        },
        "work_log[date]": {
          required: true,
        },
      },
      messages: {
        "work_log[log]": {
          required: "Please add work log hours",
        },
        "work_log[date]": {
          required: "Please select date."
        },
      }
    });
    $("#edit_work_log_29").validate({
      rules: {
        "work_log[log]": {
          required: true,
        },
        "work_log[date]": {
          required: true,
        },
      },
      messages: {
        "work_log[log]": {
          required: "Please add work log hours",
        },
        "work_log[date]": {
          required: "Please select date."
        },
      }
    });
  }
}
