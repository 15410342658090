import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="working-shift"
export default class extends Controller {
  connect() {
    $(".filter-btn").click(function(){
      $(".organizer-working-shift").toggleClass("order-enable");
    });

    customSelect2('#break_hours', true);
    customSelect2('#break_minutes', true);

    $('.WorkingShift').validate({
      rules: {
        "working_shift[name]": {
          required: true,
          minlength: 2,
          maxlength: 50,
          nameregex: true
        },
        "working_shift[start]": {
          required: true,
        },
        "working_shift[end]": {
          required: true,
        }
      },
      messages: {
        "working_shift[name]": {
          required: 'Please enter shift name',
          minlength: 'Must be 2-50 characters long',
          maxlength: 'Must be 2-50 characters long',
          nameregex: 'Only alphabets are allowed'
        },
        "working_shift[start]": {
          required: "Please select shift starting time",
        },
        "working_shift[end]": {
          required: "Please select shift ending time",
        }
      }
    });

    $('.settingFrom').validate({
      rules: {
        "org_setting[grace_time]": {
          required: true,
          min: 0
        }
      },
      messages: {
        "org_setting[grace_time]": {
          required: 'Please enter grace time',
          min: "Grace time can't be negative "
        }
      }
    });
    
    $('#working_shift_start, #working_shift_end').on('change', function() {
      changeBreakTimeSelectOption(0, 0)
    });
    changeBreakTimeSelectOption( parseInt($('#hrs').text()), parseInt($('#min').text()) );
    function changeBreakTimeSelectOption(hrs, min){
      let [hours1, minutes1] = $('#working_shift_start').val().split(':').map(Number) || 0;
      let [hours2, minutes2] = $('#working_shift_end').val().split(':').map(Number) || 0;
      let difference = Math.abs((hours2 * 60 + minutes2) - (hours1 * 60 + minutes1)) || 0;
      
      let hours = Math.floor(difference / 60) || 0;
      let minutes = difference > 60 ? 59 : difference;
      hours = (hours > 0) ? (hours - 1) : 0;

      $("#break_minutes option").remove();
      $("#break_hours option").remove();
      for (let i = 0; i <= hours; i++) {
        $("#break_hours").append(`<option value=${i}>${i}</option>`);
      }
      for (let i = 0; i <= minutes; i++) {
        $("#break_minutes").append(`<option value=${i}>${i}</option>`);
      }

      $("#break_hours").val(hrs);
      $("#break_minutes").val(min);
    }


    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    });

  }
}
