import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="emploment-type"
export default class extends Controller {
  connect() {
    // $(document).on("click", "#filter-button", function () {
    //   $('#filter-section').toggleClass('d-none')
    // });
    $(".filter-btn").click(function(){
      $(".organizer-employment-type").toggleClass("order-enable");
    });
    validatorBasic();
    $('input').on('keyup', function() {
      $('.errors').text('')
    });
    $('.employmentTypeForm').validate({
      rules: {
        "employment_type[name]": {
          required: true,
          minlength: 2,
          maxlength: 50,
          nameregex: true
        }
      },
      messages: {
      "employment_type[name]": {
        required: 'Employement type name is required.',
        minlength: 'Must be 2-50 characters long',
        maxlength: 'Must be 2-50 characters long',
        nameregex: 'Contains alphabets only'
      }
    }});
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');



    // $('#designation_start_date').datepicker({
    //   format: 'dd/mm/yyyy',
    //   endDate: '+0d',
    //   autoclose: true
    // })

    // $('#designation_end_date').datepicker({
    //   format: 'dd/mm/yyyy',
    //   endDate: '+0d',
    //   autoclose: true
    // })
  }
}
