import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-separation"
export default class extends Controller {
  connect() {
    console.log('testing')
    $('.EmployeeSeparationFrom').validate({
      rules: {
        'employee_separation[title]': {
          required: true,
          minlength: 3,
          maxlength: 50,
          notonlyspace: true
        },
        'employee_separation[reason]': {
          required: true
        }
      },
      messages: {
        'employee_separation[title]': {
          required: 'Please enter title.',
        },
        'employee_separation[reason]': {
          required: 'Please enter reason.'
        }
      }
    });

    $.validator.addMethod('notonlyspace', function(value, element){
      return this.optional(element) || /\S/.test(value);
    },'Title cannot contain only spaces.');

    $('.edit_employee').validate({
      rules: {
        'employee[end_date]': {
          required: true,
        }
      },
      messages: {
        'employee[end_date]': {
          required: 'Please select end date'
        }
      }
    });

    $("#end_date_time").datepicker({
      format: 'dd-mm-yyyy',
      startDate: new Date(),
      autoclose: true,
    });

    const checkbox = $('#employee_separation_notice_period');
    checkbox.on('click', function() {
      $(".notice_period_button").trigger("click");
    });

    console.log('Testing')
    
    if (checkbox.is(':checked')) {
      checkbox.prop('disabled', true);
    } else {
      if ($('#employee_separation_notice_period').data('separationRequestStatus') == 'exit_initiated'){
        checkbox.prop('disabled', false);
      }else{
        checkbox.prop('disabled', true);
      }
    }
  }
}
