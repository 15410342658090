import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="organization-chart"
export default class extends Controller {
  connect() {
    var data = $('#hidden').text()
    if (temp) {
      var temp = JSON.parse(data)
      console.log(temp)
      Highcharts.chart('organization-chart-container', {

        chart: {
          height: 600,
          inverted: false
        },

        title: {
          text: ''
        },

        plotOptions: {
          series: {
            nodeWidth: '220px'
          }
        },

        series: [{
          type: 'organization',
          name: 'Employee',
          keys: ['from', 'to'],
          data: temp,
          colorByPoint: false,
          borderColor: 'white'
        }],

        tooltip: {
          outside: true,
        },

        exporting: {
          allowHTML: true,
        }
      });
    }
  }
}