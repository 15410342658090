import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee--academic-detail"
export default class extends Controller {
  connect() {
    if($('#employee_academic_section .nested-fields-academic-detail').length == 0){
      $('#employee_academic_section .add_fields').click()
    }

    setTimeout(function() {
      loadDatePickerOnAcademic();
    }, 100)
    $('#employee_academic_section .add_fields').click(function() {
      setTimeout(function() {
        loadDatePickerOnAcademic();
      }, 100)
    })
  }
}

window.loadDatePickerOnAcademic = function() {
  $('.date-picker').each(function(index, item) {
    $(item).datepicker({
      format: 'dd-mm-yyyy',
      format: 'dd-mm-yyyy',
      autoclose: true,
      startDate: '01/01/1900',
      endDate: new Date().toLocaleDateString('en-GB')
    })
  })
}
