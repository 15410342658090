import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="attendance"
export default class extends Controller {
  connect() {
    $('.status-select').each(function() {
      var selectId = $(this).attr('id');
      var selectedValue = $(this).val();
      var selectedColor = $('#' + selectId + ' option[value="' + selectedValue + '"]').css('color');
      $('#' + selectId).css('color', selectedColor);
    });

    document.addEventListener('turbo:load', () => {
      this.initializeDatatable();
    });
    document.addEventListener('refreshMonthlyAttendanceDataTable', (event) => {
      console.log('----------refresh---------')
      this.refreshMonthlyAttendanceDataTable(event.detail);
    });

    $('#attendance_month').datepicker({
      format: 'mm/yyyy',
      minViewMode: 'months',
      autoclose: true
    });
    
    var attendanceMonthValue = $('#attendance_month').val();
    if (attendanceMonthValue != undefined) {
      var month = $('#attendance_month')[0].value.split('/')[0]
      var year = $('#attendance_month')[0].value.split('/')[1]
      var firstDate = new Date(year, month - 1, 1);
      var nextMonthDate = new Date(year, month, 1);
      var lastDate = new Date(nextMonthDate - 1);
      if (attendanceMonthValue == '' || attendanceMonthValue === 'nil') {
        $('#attendance_date').prop('disabled', true);
      } else {
        $('#attendance_date').prop('disabled', false);
        $('#attendance_date').datepicker({
          format: 'dd/mm/yyyy',
          startDate: firstDate,
          endDate:lastDate,
          autoclose: true
        });
      }
      
      $('#attendance_month').on('change', function(){
        checkAttendanceMonthValue();
      });

      $('#attendance_month, #emp_attendance_report_status').change(function() {
        var attendance_month = $('#attendance_month').val();
        var status = $('#emp_attendance_report_status').val();
        $.ajax({
          url: $('#monthly-attendance-report-datatable').data('source'),
          method: 'GET',
          data: { attendance_month: attendance_month,
                  status: status },
          success: function(response) {
          }
        });
      });

      function checkAttendanceMonthValue(){
        var attendanceMonthValue = $('#attendance_month').val();
        var month = $('#attendance_month')[0].value.split('/')[0]
        var year = $('#attendance_month')[0].value.split('/')[1]
        var firstDate = new Date(year, month - 1, 1);
        var nextMonthDate = new Date(year, month, 1);
        var lastDate = new Date(nextMonthDate - 1);
        if (attendanceMonthValue == '' || attendanceMonthValue === 'nil') {
          $('#attendance_date').prop('disabled', true);
        } else {
          $('#attendance_date').prop('disabled', false);
          $('#attendance_date').datepicker({
            format: 'dd/mm/yyyy',
            startDate: firstDate,
            endDate:lastDate,
            autoclose: true
          });
        }
      }

      if ($("#role_employee_feature").is(":checked")) {
        $(".employee-permission input[type=checkbox]").each(function(index) {
          $(this).removeAttr('disabled')
          $(this).removeClass('disable-text')
        });
        $(".employee-permission label").removeClass('disable-text')
      } else {
        $(".employee-permission input[type=checkbox]").each(function(index) {
          $(this).prop('disabled', 'disabled')
          $(this).prop('checked', false)
          $(this).addClass('disable-text')
        });
        $(".employee-permission label").addClass('disable-text')
      }
    }

    var currentDate = new Date();
    var lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    $("#from_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: lastDayOfMonth
    }).on('changeDate input', function(selected) {
      if (!$(this).val()) {
        $('#to_date').datepicker('setStartDate', null);
      } else if (selected.date != undefined) {
        var minDate = new Date(selected.date.valueOf());
        $('#to_date').datepicker('setStartDate', minDate);
        var maxDate = new Date(minDate.getTime());
        maxDate.setDate(maxDate.getDate() + 60);
        if(maxDate.getMonth() > lastDayOfMonth){
          maxDate.setDate(lastDayOfMonth)
        }
        $('#to_date').datepicker('setEndDate', maxDate);
      }
    });

    $("#to_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: lastDayOfMonth
    }).on('changeDate input', function(selected) {
      if (!$(this).val()) {
        $('#from_date').datepicker('setEndDate', lastDayOfMonth);
      } else if (selected.date != undefined) {
        var maxDate = new Date(selected.date.valueOf());
        $('#from_date').datepicker('setEndDate', maxDate);        
      }
    });
    customSelect2('#attendance_year', true, 'Year');

    var initialFromDate = $('#from_date').datepicker('getDate');
    if(initialFromDate != null){
      $('#to_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#to_date').datepicker('getDate');
    if(initialToDate != null){
      $('#from_date').datepicker('setEndDate', initialToDate);
    }

    $('#attendance_month').datepicker({
      format: 'mm/yyyy',
      minViewMode: 'months',
      autoclose: true
    });
    
    function formatDateToCustom(dateString) {
      var parts = dateString.split('-');
      var year = parts[0];
      var month = parts[1];
      var day = parts[2];

      var monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      var fullMonthName = monthNames[parseInt(month) - 1];
      return day + ' ' + fullMonthName;
    }

    $(document).ready(function() {
      $('#attendance_year').change(function() {
        var attendanceMonth = $('#attendance_year').val();
        $.ajax({
          url: '/employee/dashboard/attendance_charts',
          method: 'GET',
          data: { month: attendanceMonth },
          success: function(response) {
            var chartData = response;
            employee_attendance_dashboard(chartData);
          }
        });
      });
      var element = $('.employee-attendance-dashboard');
      if ( element.length > 0 ) {
        const chartData = JSON.parse($('#current_year_attendance').val());
        employee_attendance_dashboard(chartData);
      }
    });

    var selectOption = $('#time_period');
    var fromDate = $('#from_date');
    var toDate = $('#to_date');

    $('#time_period, #from_date, #to_date').on('change', function(){
      var selectOption = $('#time_period');
      if (selectOption.length > 0) {
        var fromDate = $('#from_date');
        var toDate = $('#to_date');

        let isSelectOptionEmpty = selectOption.val() === "";
        let isFromDateEmpty = fromDate.val() === "";
        let isToDateEmpty = toDate.val() === "";

        console.log('option: '+!isSelectOptionEmpty)
        console.log('from: '+!isFromDateEmpty)
        console.log('to: '+!isToDateEmpty)

        toDate.prop('disabled', !isSelectOptionEmpty);
        fromDate.prop('disabled', !isSelectOptionEmpty);
        selectOption.prop('disabled', (!isFromDateEmpty || !isToDateEmpty));
      }
    });

    if (selectOption.length > 0) {
      fromDate.prop('disabled', (selectOption.val() != ''));
      toDate.prop('disabled', (selectOption.val() != ''));
      selectOption.prop('disabled', (fromDate.val() != '' || toDate.val() != ''));
    }
    
    function employee_attendance_dashboard(chartData) {
       var attendanceYear = $('#attendance_year').val();
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();

        if (!attendanceYear) {
          attendanceYear = currentYear;
        } else {
          attendanceYear = parseInt(attendanceYear, 10);

          if (isNaN(attendanceYear)) {
            return;
          }
        }

        var monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        var filteredData = chartData.filter(function(monthData) {
          return monthData.month >= 1 && monthData.month <= 12 && monthData.total_count >= 0;
        });

        var months = filteredData.map(function(monthData) {
          return monthData.month;
        });

        var attendanceCounts = filteredData.map(function(monthData) {
          return monthData.total_count;
        });

        Highcharts.chart('attendance_dashboard', {
          chart: {
            type: 'column'
          },
          title: {
            text: 'Monthly Attendance for ' + attendanceYear
          },
          xAxis: {
            categories: months.map(function(month) {
              return monthNames[month - 1];
            }),
            title: {
              text: ''
            }
          },
          yAxis: {
            title: {
              text: 'Attendance Count'
            }
          },
          series: [
            {
              name: attendanceYear,
              data: attendanceCounts
            }
          ],
          tooltip: {
            formatter: function () {
              var monthIndex = this.x;
              var formattedDate = monthNames[monthIndex];
              return '<br>Attendance: ' + this.y;
            }
          }
        });
    }

    $(document).ready(function() {
      $('#attendance_month').change(function() {
        var attendanceMonth = $('#attendance_month').val();
        $.ajax({
          url: '/organizers/dashboard/attendance_charts',
          method: 'GET',
          data: { month: attendanceMonth },
          success: function(response) {
            var chartData = response;
            attendance_dashboard(chartData);
          }
        });
      });
      var element = $('.organizer-attendance-dashboard');

      if ( element.length > 0) {
        const chartData = JSON.parse($('#current_year_and_month_attendance').val());
        attendance_dashboard(chartData);
      }
    });

    function attendance_dashboard(chartData) {
      var attendanceMonth = $('#attendance_month').val();
      if (attendanceMonth){
        var dateParts = attendanceMonth.split('/');
        var month = parseInt(dateParts[0], 10);
        var year = parseInt(dateParts[1], 10);
      }else{
        var currentDate = new Date();
        var year = currentDate.getFullYear();
        var month = currentDate.getMonth() + 1;
      }
      var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      var monthName = monthNames[month - 1];
      var daysInMonth = new Date(year, month, 0).getDate();

      var dates = [];
      for (var day = 1; day <= daysInMonth; day++) {
        var dayString = (day < 10 ? '0' : '') + day;
        var dateString = year + '-' + (month < 10 ? '0' : '') + month + '-' + dayString;
        dates.push(dateString);
      }

      var attendanceCounts = dates.map(function(date) {
        var dataPoint = chartData.find(function(item) {
          return item.date === date;
        });

        return {
          day: date.split('-')[2],
          date: date,
          count: dataPoint ? dataPoint.count : 0
        };
      });

      Highcharts.chart('attendance_dashboard', {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: attendanceCounts.map(function(data) {
            return data.day;
          })
        },
        yAxis: {
          title: {
            text: 'Attendance Count'
          }
        },
        series: [
          {
            name: monthName + ' ' + year,
            data: attendanceCounts.map(function(data) {
              return data.count;
            })
          }
        ],
        tooltip: {
          formatter: function () {
            var dataPoint = attendanceCounts.find(function(data) {
              return data.day === this.x;
            }, this);

            if (dataPoint) {
              var formattedDate = formatDateToCustom(dataPoint.date);
              return 'Date: ' + formattedDate + '<br>Attendance: ' + dataPoint.count;
            } else {
              return 'Date: N/A<br>Attendance: N/A';
            }
          }
        }
      });
    }
  }

  filterByTime() {
    if($('#search-form-attendance select').val() == 'Custom') {
      $('.custom-search').show();
    }else{
      $('#search-form-attendance').submit();
    }
  }

  initializeDatatable() {
    let table_id = $('.table-id-element').attr('id')
    if (!$.fn.DataTable.isDataTable('#'+ table_id)) {
      console.log('checking the load')
      $('#'+ table_id).dataTable({
        "orderCellsTop": true,
        "processing": true,
        "pagingType": "simple_numbers",
        "oLanguage": {
          "sLengthMenu": "Show Entries _MENU_",
          "sProcessing": "<div class='ld_ind_wrp'></div>",
          "sSearch": "",
          "oPaginate": {
            "sNext": '<i class="bi bi-chevron-right"></i>',
            "sPrevious": '<i class="bi bi-chevron-left"></i>'
          },
        },
        "dom": "<'row custom_data_filter_col'<'col-sm-12 custom_datatable'f>>" +
          "<'row'<'col-sm-12 table-responsive'tr>>" +
        "<'row mgltm0'<'col-sm-5 show-entry'li><'col-sm-7'p>>"+ "<'row'<'col-sm-12'B>>" ,
          'buttons': [
            {
              extend: 'csv',
              text: 'Export CSV',
            },
            {
              extend: 'excel',
              text: 'Export Excel',
            }
          ],
      });
    }
  }

  refreshMonthlyAttendanceDataTable(detail) {
    console.log('Monthly Attendance')
    let table_id = detail.table_id
    if ($.fn.DataTable.isDataTable('#' + table_id)) {
      $('#' + table_id).DataTable().destroy();
    }
    this.initializeDatatable();
  }
}

