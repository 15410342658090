import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="loading"
export default class extends Controller {
  connect() {
    

     $('#counting').validate({
      rules: {
        "anything[count]":{
          required: true,
          min: 5,
        },
      },
      messages: {
        "anything[count]":{
          required: 'This is a required field.',
          min: 'Minimum allowed number is 5',
        },
      }
    })
  }
}
