import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact-us"
export default class extends Controller {
  connect() {
    $('.contact-us-form').validate({
      rules:{
        'contact_us[name]':{
          required:true,
          minlength: 3,
          maxlength: 40,
          nameRegex: true
        },
        'contact_us[mobile_number]':{
          required:true,
          minlength: 10,
          maxlength: 10
        },
        'contact_us[email]':{
          required:true,
          emailRegex: true
        },
        'contact_us[company_name]':{
          required:true
        }
      },
      messages:{
        'contact_us[name]':{
          required:'Please enter name ',
          minlength: 'Enter minimum 3 and maximum 40 characters',
          maxlength: 'Enter minimum 3 and maximum 40 characters',
        },
        'contact_us[email]':{
          required: 'Please enter Email id ',
          emailRegex: 'Please enter valid email id'
        },
        'contact_us[mobile_number]':{
          required: 'Please enter mobile number ',
        },
        'contact_us[company_name]':{
          required: 'Please enter company name ', 
        }
      },
      errorElement: "span"
    });
    jQuery.validator.addMethod("emailRegex", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/i.test(value);
    }, "Enter valid email address");
    jQuery.validator.addMethod("nameRegex", function(value, element) {
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    }, "Enter only alphabets");
  }
}
