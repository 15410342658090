import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="work-from-home"
export default class extends Controller {
  connect() {

    customSelect2('#status', true, 'All')

    var working_days = []
    var disabled_days = []
    var disabled_dates = []
    var days = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6
    }
    
    if ( $("#holiday").length != 0 ){ 
      var holidays = JSON.parse($("#holiday").text())
      holidays.forEach((value) => working_days.push(days[value]));
      disabled_days = [0,1,2,3,4,5,6].filter((o) => working_days.indexOf(o) === -1)
    }
    if ( $("#holiday-dates").length != 0 ){
      disabled_dates = JSON.parse($("#holiday-dates").text())
    }
    if ( $("#wfh-dates").length != 0 ){
      disabled_dates = JSON.parse($("#wfh-dates").text())
    }
    if ( $("#leave-dates").length != 0 ){
      disabled_dates = JSON.parse($("#leave-dates").text())
    }

    $(".filter-btn").click(function(){
      $(".employee-work-from-home").toggleClass("order-enable");
    });

    $(".datepicker").attr("autocomplete", "off");

    var startDate;
    $('#work_from_home_from_date').datepicker({
      format: 'dd-mm-yyyy',
      endDate: '+1y',
      startDate: '0d',
      daysOfWeekDisabled: disabled_days,
      datesDisabled: disabled_dates,
      autoclose: true
    }).on('changeDate input', function(selected) {
      if (!$(this).val()) {
        $('#work_from_home_to_date').datepicker('setStartDate', new Date());      
      } else {
        startDate = new Date(selected.date.valueOf());
        $('#work_from_home_to_date').datepicker('setStartDate', startDate);
      }
      if ($('#work_from_home_from_date').val() && $('#work_from_home_to_date').val()){
        var from = $("#work_from_home_from_date").val().split("-")
        var start = new Date(from[2], from[1] - 1, from[0])
        var to = $("#work_from_home_to_date").val().split("-")
        var end = new Date(to[2], to[1] - 1, to[0])
        var total = end.getTime() - start.getTime()
        var totalDay = total / (1000 * 60 * 60 * 24);
        $('.WFH-total-day').addClass('d-block')
        $('.WFH-total-day').removeClass('d-none')
        $('#total-days').val(totalDay+1)
      } else {
        $('#total-days').val(0)
      }
    });



    $('#work_from_home_to_date').datepicker({
      format: 'dd-mm-yyyy',
      endDate: '+1y',
      startDate: '+0d',
      daysOfWeekDisabled: disabled_days,
      datesDisabled: disabled_dates,
      autoclose: true
    }).on('changeDate input', function(selected) {
      if (!$(this).val()) {
        var endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() + 1);
        $('#work_from_home_from_date').datepicker('setEndDate', endDate);
      } else if (selected.date != undefined) {
        var endDate = new Date(selected.date.valueOf());
        $('#work_from_home_from_date').datepicker('setEndDate', endDate);
        if (endDate < startDate){
            $('#work_from_home_to_date').datepicker('setDate', startDate);
        }
      }
      if ($('#work_from_home_from_date').val() && $('#work_from_home_to_date').val()){
        var from = $("#work_from_home_from_date").val().split("-")
        var start = new Date(from[2], from[1] - 1, from[0])
        var to = $("#work_from_home_to_date").val().split("-")
        var end = new Date(to[2], to[1] - 1, to[0])
        var total = end.getTime() - start.getTime()
        var totalDay = total / (1000 * 60 * 60 * 24);
        $('.WFH-total-day').addClass('d-block')
        $('.WFH-total-day').removeClass('d-none')
        $('#total-days').val(totalDay+1)
      } else {
        $('#total-days').val(0)
      }
    });

    var initialFromDate = $('#work_from_home_from_date').datepicker('getDate');
    if (initialFromDate != null){
      $('#work_from_home_to_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#work_from_home_to_date').datepicker('getDate');
    if (initialToDate != null){
      $('#work_from_home_from_date').datepicker('setEndDate', initialToDate);
    }

    $('.WorkFromHome').validate({
      rules: {
        "work_from_home[from_date]":{
          required: true
        },
        "work_from_home[to_date]":{
          required: true
        },
        "work_from_home[reason]":{
          required: true,
          minlength: 10
        }
      },
      messages: {
        "work_from_home[from_date]":{
          required: 'Please enter from date.'
        },
        "work_from_home[to_date]":{
          required: 'Please enter to date.'
        },
        "work_from_home[reason]":{
          required: 'Please enter reason.',
          minlength: 'Reason must have minimum 10 character long.'
        }
      }
    })

    $('#WFHRejectForm').validate({
      rules:{
        "work_from_home[reject_comment_description]":{
          required: true,
          minlength: 10
        }
      },
      messages:{
        "work_from_home[reject_comment_description]":{
          required: 'Please enter description.',
          minlength: 'Description must have minimum 10 character long.'
        }
      }
    })
    $(document).on('turbo:load', function() {
      $(".filter-btn").click(function(){
        $(".org-wfh").toggleClass("order-enable");
      });
    })
  }
}