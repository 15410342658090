import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="performance-settings"
export default class extends Controller {
  connect() {

    customSelect2('#setting_organizer_time_interval', true);

    $(".PerformanceSetting").validate({
      rules: {
        'setting_organizer[time_interval]': {
          required: true

        },
      },
      messages: {
        'setting_organizer[time_interval]': {
          required: "Please select time interval.",
        }
      },
    });
  }


}
