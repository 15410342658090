import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="application-v2--compensatory-off"
export default class extends Controller {
  connect() {
    $(document).ready(function() {
      $(".edit_compensatory_off_setting").validate({
        rules: {
          "compensatory_off_setting[total_hours]": {
            required: true,
            number: true,
            min: 1,
            maxlength: true
          },
          "compensatory_off_setting[expires_in_days]": {
            required: true,
            number: true,
            min: 1,
            maxlength: 3
          },
        },
        messages: {
          "compensatory_off_setting[total_hours]": {
            required: "Please enter total hours.",
            number: "Please enter a valid number.",
            min: "Total hours must be 1 or more.",
            maxlength: "Total hours must be between 1-3 digit."
          },
          "compensatory_off_setting[expires_in_days]": {
            required: "Please enter the expiration duration (in days)",
            number: "Please enter a valid number",
            min: "Expiration days must be 1 or more"
          },
        },
        submitHandler: function(form) {
          form.submit();
        }
      });
    });

  }
}
