import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="home"
export default class extends Controller {
  connect() {
    $('#help_feedback_suggestion').hide()
    $('#suggestion_label').hide()
    $('input[type=radio]').on('change',function(){
      if (this.value == 'Other') {
        $('#suggestion_label').show()
        $('#help_feedback_suggestion').show()
      } else {
        $('#suggestion_label').hide()
        $('#help_feedback_suggestion').hide()
      }
    });

    $("#feedback-close-btn").click(function(){
      $("#help_feed_back").hide();
    });

    $('.HelpFeedbackForm').validate({
      rules: {
        "help_feedback[suggestion]": {
          required: true,
          minlength: 5,
          nameregex: true
        }
      },
      messages: {
      "help_feedback[suggestion]": {
        required: 'Please enter suggestion.',
        minlength: 'Minimum 5 character is required.',
        nameregex: 'Please enter valid input.'
      }
    }});
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z0-9-=()., ]+$/.test(value);
    },'Please enter valid input.');

  }
}
