import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-bulk-upload"
export default class extends Controller {
  connect() {
    console.log($('#employe_upload_save').data("value"));
    $(document.body).removeAttr("style");
    setTimeout(function(){
      $('.modal-backdrop').css('display', 'none');
      var mappingLabel = $('#employe_upload_save').data("value");
      $("#mappings").val(mappingLabel).trigger('change');
    }, 600);
  }
}
