import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-salary-setting"
export default class extends Controller {
  connect() {

    $(".filter-btn").click(function(){
      $(".organizer-salary-setting").toggleClass("order-enable");
    });
    
    $(".employee-salary-rule").validate({
      rules: {
        "employee_salary_rule[applied_on]": {
          required: true,
        },
        "employee_salary_rule[calculate_by_percent]": {
          required: true,
          percentage_validator: true,
        },
        "employee_salary_rule[calculate_by_amount]": {
          required: true,
          number_regex: true,
          maxlength: 10
        }
      },
      messages: {
        "employee_salary_rule[applied_on]": {
          required:  'Please select applied on',
        },
        "employee_salary_rule[calculate_by_percent]": {
          required:  'Please enter percent',
        },
        "employee_salary_rule[calculate_by_amount]": {
          required:  'Please enter amaount',
          maxlength: 'only 10 digits are allowed'
        }
      }
    });

    $.validator.addMethod('number_regex', function(value, element) {
     return this.optional(element) || /^[\d,]+$/.test(value);
    }, 'Only digits and commas are allowed');

    $.validator.addMethod('percentage_validator', function(value, element) {
      return this.optional(element) || /^(?:\d{1,2}(?:\.\d{1,2})?)?$/.test(value);
    }, 'Values upto 2 decimal places are allowed');

    customSelect2('#employee_salary_rule_applied_on', true);
    customSelect2('#employee_ids', true, 'All Selected');

    $('.salary-calculate-amount').hide();
    $('.salary-calculate-percent').show();

    if ($('#employee_salary_rule_calculation_type_flat').is(':checked')) {
      $('.salary-applied-on').hide();
      $('.salary-calculate-percent').hide();
      $('.salary-calculate-amount').show();
    }
    
    if ($('#employee_salary_rule_calculation_type_additional').is(':checked')) {
      $('.salary-calculate-percent').hide()
      $('.salary-applied-on').show()
      $('.salary-calculate-amount').hide()
    }
    
    $('#employee_salary_rule_calculation_type_percentage').change(function(){
      $('.salary-calculate-amount').hide()
      $('.salary-applied-on').show()
      $('.salary-calculate-percent').show()
    });

    $('#employee_salary_rule_calculation_type_flat').change(function(){
      $('.salary-calculate-percent').hide()
      $('.salary-applied-on').hide()
      $('.salary-calculate-amount').show()
    });

    $('#employee_salary_rule_calculation_type_additional').change(function(){
      $('.salary-calculate-percent').hide()
      $('.salary-applied-on').show()
      $('.salary-calculate-amount').hide()
    });

    
    $("#salary_rule_name").change(function(){
      var currentValue = $(this).val();
      var employeeId = $(this).data("employeeId");
      console.log(employeeId)

      $.ajax({
        type: "GET",
        url: "/organizers/employee_salary_settings/add_salary_rule",
        data: { salary_rule_name: currentValue, employee_id: employeeId },
        success: function(response) {
          $("#salary_rule_name option:selected").prop("selected", false)
        }
      });
    });

    $("#deduction_rule").change(function(){
      var currentValue = $(this).val();
      console.log(currentValue)
      var employeeId = $(this).data("employeeId");

      $.ajax({
        type: "GET",
        url: "/organizers/employee_salary_settings/add_deduction_rule",
        data: { deduction_rule: currentValue, employee_id: employeeId },
        success: function(response) {
          $("#deduction_rule option:selected").prop("selected", false)
        }
      });
    });

    $("#comp_off").change(function(){
      var currentValue = $(this).val();
      var employeeId = $(this).data("employeeId");

      $.ajax({
        type: "GET",
        url: "/organizers/employee_salary_settings/add_comp_offs",
        data: { comp_off_id: currentValue, employee_id: employeeId },
        success: function(response) {
        }
      });
    });

    $("#benefit").change(function(){
      var currentValue = $(this).val();
      console.log(currentValue)
      var employeeId = $(this).data("employeeId");

      $.ajax({
        type: "GET",
        url: "/organizers/employee_salary_settings/add_benefit",
        data: { employee_badge_id: currentValue, employee_id: employeeId },
        success: function(response) {
        }
      });
    });

    $("#reimbursment").change(function(){
      var currentValue = $(this).val();
      console.log(currentValue)
      var employeeId = $(this).data("employeeId");

      $.ajax({
        type: "GET",
        url: "/organizers/employee_salary_settings/add_reimbersement",
        data: { reimbersement_id: currentValue, employee_id: employeeId },
        success: function(response) {
        }
      });
    });

    $("#referral").change(function(){
      var currentValue = $(this).val();
      console.log(currentValue)
      var employeeId = $(this).data("employeeId");

      $.ajax({
        type: "GET",
        url: "/organizers/employee_salary_settings/add_referral",
        data: { referral_id: currentValue, employee_id: employeeId },
        success: function(response) {
        }
      });
    });

    $(document).ready(function() {
      $('.loan-installments-checkbox').change(function() {
        var selectedIds = [];
        var uncheckedIds = [];

      $('.loan-installments-checkbox').each(function() {
        if ($(this).is(':checked')) {
          selectedIds.push($(this).data('id'));
        } else {
          uncheckedIds.push($(this).data('id'));
        }
      });
      $('#loan_installments_ids').val(selectedIds);
      $('#unchecked_ids').val(uncheckedIds);
      });
    });
  }

  showConfirmation(){
    return confirm('Are you sure, you want to save changes ?') 
  }
}
