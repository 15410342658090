import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="daily-card-logs"
export default class extends Controller {
  connect() {
    $('.punch_in_and_out_link').on('click', function() {
      $('.popover-body').remove();
    });
    $(document).ready(function () {
      const video = document.getElementById('camera-feed');
      const canvas = document.getElementById('image-canvas');
      if (video && canvas) {
        const context = canvas.getContext('2d');
        const captureButton = document.getElementById('capture-button');
        const capturedImage = document.getElementById('captured-image');
        const imageDataInput = document.getElementById('picture-input');
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

          navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            video.srcObject = stream;
          })
          .catch((error) => {
            console.error('Error accessing camera:', error);
          });

        captureButton.addEventListener('click', () => {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageData = canvas.toDataURL('image/jpeg');

          document.getElementById('image_data').value = imageData
        });
      }
    });
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {"html": true})
    })
  }
}
