import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false,
      backdrop: 'static'
    })

    this.modal.show()
    var source = $('#candidate_source').val()
    if (source == 'reference') {
      $('#other_details').addClass('d-none')
      $('#other_details').removeClass('d-block')
      $('.reference_details').addClass('d-block')
      $('.reference_details').removeClass('d-none')
    } else if ( source == 'other') {
      $('#other_details').addClass('d-block')
      $('#other_details').removeClass('d-none')
      $('.reference_details').addClass('d-none')
      $('.reference_details').removeClass('d-block')
    } else {
      $('#other_details').addClass('d-none')
      $('#other_details').removeClass('d-show')
      $('.reference_details').addClass('d-none')
      $('.reference_details').removeClass('d-block')
    }
  }
  disconnect() {
    this.modal.hide()
  }
}