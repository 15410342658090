import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="deduction"
export default class extends Controller {
  connect() {

    $(".filter-btn").click(function(){
      $(".organizer-deduction").toggleClass("order-enable");
    });
    $(".DeductionRule").validate({
      rules: {
        "deduction[deduction_rule]": {
          required: true,
        },
        "deduction[employee_contribution_rate]": {
          required: true,
          percentage_validator: true,
        },
        "deduction[employer_contribution_rate]": {
          required: true,
          percentage_validator: true,
        },
        "deduction[employee_contribution]": {
          required: true,
          number_regex_decimal: true,
          maxlength: 10
        },
        "deduction[employer_contribution]": {
          required: true,
          number_regex_decimal: true,
          maxlength: 10
        },
        "deduction[limit_amount]": {
          required: true,
          number_regex: true,
          maxlength: 10
        },
        "deduction[applied_on]": {
          required: true,
        },
        "deduction[from_deduction]": {
          required: true,
        },
        "deduction[to_deduction]": {
          required: true,
          fromDateBeforeToDate: true
        }
      },
      messages: {
        "deduction[deduction_rule]": {
          required: 'Please select Deduction rule.',
        },
        "deduction[employee_contribution_rate]": {
          required:  'Please enter employee contribution rate',
        },
        "deduction[employer_contribution_rate]": {
          required:  'Please enter employer contribution rate',
        },
        "deduction[employee_contribution]": {
          required:  'Please enter employee contribution',
          maxlength: 'Only 10 digits are allowed'
        },
        "deduction[employer_contribution]": {
          required:  'Please enter employer contribution',
          maxlength: 'Only 10 digits are allowed'
        },
        "deduction[limit_amount]": {
          required:  'Please enter limit amount',
          maxlength: 'Only 10 digits are allowed'
        },
        "deduction[applied_on]": {
          required:  'Please select applied on',
        },
        "deduction[from_deduction]": {
          required:  'Please select financial year',
        },
        "deduction[to_deduction]": {
          required:  'Please select financial year',
        }
      }
    });

    $.validator.addMethod('number_regex_decimal', function(value, element) {
      return this.optional(element) || /^[\d,]+(\.\d+)?$/.test(value);
    }, 'Only digits, commas, and decimals are allowed');

    $.validator.addMethod('percentage_validator', function(value, element) {
      return this.optional(element) || /^(?:\d{1,2}(?:\.\d{1,2})?)?$/.test(value);
    }, 'Values upto 2 decimal places are allowed');

    $.validator.addMethod("fromDateBeforeToDate", function(value, element) {
      var fromDate = $('#deduction_from_deduction').datepicker('getDate');
      var toDate = $('#deduction_to_deduction').datepicker('getDate');

      return fromDate && toDate && fromDate < toDate;
    }, "To date must be greater than from date");

    customSelect2('#organizer_deduction_rule', true);
    customSelect2('#deduction_applied_on', true);
    customSelect2('#deduction_deductible_contribution', true);
    customSelect2('#deduction_earning_contribution', true);
    customSelect2('#deduction_deduction_contribution', true);

    $('.employee-contribution, .professional-tax, .deduction-cycle, .pf-limit-amount, .pf-celling-limit, .employee-contribution-rate, .deduction-status, .deduction-description, .deduction-applied-on, .deductible_contribution_field, .mandatory-for-employee, .deduction_calculation_type, .deduction-calculate-amount, .show_in_salary_slip').hide();
    function updateFieldsVisibility(deduction_rule) {
      $('.employee-contribution, .professional-tax, .deduction-cycle, .pf-limit-amount, .pf-celling-limit, .employee-contribution-rate, .deduction-status, .deduction-description, .mandatory-for-employee, .deduction-applied-on, .deduction_calculation_type, .show_in_salary_slip').hide();
      if (deduction_rule === 'epf' || deduction_rule === 'Epf') {
        if ($('#deduction_calculation_type_flat').is(':checked')){
          $('.deduction-status, .deduction-description, .deductible_contribution_field, .deduction_calculation_type, .show_in_salary_slip').show();
        }else{
          $('.pf-celling-limit, .employee-contribution-rate, .deduction-status, .deduction-description, .deduction-applied-on, .deductible_contribution_field, .deduction_calculation_type, .show_in_salary_slip').show();
        }
      } else if (deduction_rule == 'esic' || deduction_rule == 'Esic') {
        console.log(deduction_rule);
        if ($('#deduction_calculation_type_flat').is(':checked')){
          $('.deduction-status, .deduction-description, .deductible_contribution_field, .deduction_calculation_type, .show_in_salary_slip').show();
        }else{
          $('.pf-celling-limit, .employee-contribution-rate, .deduction-applied-on, .deduction-status, .deduction-description, .mandatory-for-employee, .deductible_contribution_field, .deduction_calculation_type, .show_in_salary_slip').show();
        }
      } else if (deduction_rule == 'Professional Tax' || deduction_rule == 'professional_tax') {
        console.log(deduction_rule);
        $('.professional-tax, .deduction-cycle, .deduction-applied-on, .deduction-status, .deduction-description').show();
      } else if (deduction_rule == 'Labour Welfare Fund' || deduction_rule == 'labour_welfare_fund') {
        console.log(deduction_rule);
        $('.employee-contribution, .deduction-cycle, .deduction-status, .deduction-description').show();
      }
    }

    $(document).ready(function() {
      var selectedDeductionRule = $('#organizer_deduction_rule_update').val();
      updateFieldsVisibility(selectedDeductionRule);
      console.log(selectedDeductionRule);

      $('#organizer_deduction_rule').change(function() {
        var deduction_rule = $(this).val().toLowerCase();
        console.log(deduction_rule);
        updateFieldsVisibility(deduction_rule);
      });

      if ($('#deduction_pf_celling_limit').is(':checked') && !$('#deduction_calculation_type_flat').is(':checked')) {
        $('.deduction-applied-on').hide();
        $('.pf-limit-amount').show();
      }
      $('#deduction_pf_celling_limit').change(function() {
        if ($(this).is(':checked')) {
          $('.pf-limit-amount').show();
          $('.deduction-applied-on').hide();
        } else {
          $('.pf-limit-amount').hide();
          $('.deduction-applied-on').show();
        }
      });

      $('.deduction-calculate-amount').hide();
      if ($('#deduction_calculation_type_flat').is(':checked')) {
        $('.employee-contribution-rate').hide();
        $('.deduction-applied-on').hide();
        $('.deduction-limit-amount').hide();
        $('.pf-celling-limit').hide();
        $('.deduction-calculate-amount').show();
      }

      $('#deduction_calculation_type_flat').change(function(){
        $('#deduction-calculate-amount').show();
        $('.employee-contribution-rate').hide();
        $('.deduction-applied-on').hide();
        $('.deduction-calculate-amount').show();
        $('.deduction-limit-amount').hide();
        $('.pf-celling-limit').hide();
      });

      $('#deduction_calculation_type_percentage').change(function(){
        if ($('#deduction_pf_celling_limit').is(':checked')) {
          $('.pf-limit-amount').show();
          $('.deduction-applied-on').hide();
        }
        $('.employee-contribution-rate').show();
        $('.deduction-calculate-amount').hide();
        $('.deduction-limit-amount').show();
        $('.pf-celling-limit').show();
        $('.deduction-calculate-amount').hide();
      });
    });

    $('#deduction_from_deduction, #deduction_to_deduction').datepicker({
      dateFormat: 'yyyy',
      changeMonth: false,
      minViewMode: 'years',
      autoclose: true,
    }).attr("autocomplete", "off");

  }
}
