import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard-charts"
export default class extends Controller {
  connect() {
    // customSelect2('#pay_years', true, 'Year');
    // customSelect2('#work_logs', true, 'Today');
    // customSelect2('#team_employee_task', true, 'My Task');
    // customSelect2('#pay_years_section', true, 'Year');

    $(document).ready(function() {
      $('#pay_years_section').change(function() {
        var payYearsValue = $(this).val();
        console.log(payYearsValue);
        $.ajax({
          url: '/employee/dashboard/payoff_section',
          method: 'GET',
          data: { year: payYearsValue },
          success: function(response) {
            var searchYearPayoffs = response.search_year_payoffs;
            var lastYearPayoffs = response.last_year_payoffs;
            var lastYear = payYearsValue - 1
            myChart(payYearsValue, lastYear, searchYearPayoffs, lastYearPayoffs)
          },
          error: function(error) {
            console.error('Error:', error);
          }
        });
      });
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var lastYear = currentYear - 1;
      // const current_year_payoffs = JSON.parse($('#current_year_payoffs').val());
      // const last_year_payoffs = JSON.parse($('#last_year_payoffs').val());
      // myChart(currentYear, lastYear, current_year_payoffs, last_year_payoffs)
    });
    
    $(document).ready(function() {
      $('#pay_years').change(function() {
        var payYearsValue = $(this).val();
        console.log(payYearsValue);
        $.ajax({
          url: '/organizers/dashboard/payoff_charts',
          method: 'GET',
          data: { year: payYearsValue },
          success: function(response) {
            var searchYearPayoffs = response.search_year_payoffs;
            var lastYearPayoffs = response.last_year_payoffs;
            var lastYear = payYearsValue - 1
            myChart(payYearsValue, lastYear, searchYearPayoffs, lastYearPayoffs)
          },
          error: function(error) {
            console.error('Error:', error);
          }
        });
      });
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var lastYear = currentYear - 1;
      // const current_year_payoffs = JSON.parse($('#current_year_payoffs').val());
      // const last_year_payoffs = JSON.parse($('#last_year_payoffs').val());
      // myChart(currentYear, lastYear, current_year_payoffs, last_year_payoffs)
    });

    // function allZeros(arr) {
    //   return arr.every(function(value) {
    //     return value === 0;
    //   });
    // }

    function myChart(currentYear, lastYear, current_year_payoffs, last_year_payoffs){
      // if (allZeros(current_year_payoffs) && allZeros(last_year_payoffs)) {
      //   document.getElementById('chart-container').innerHTML = '<div class="no-data" style="text-align: center;">No data available</div>';
      // } else {
        const chartData = [
          { name: lastYear, data: last_year_payoffs },
          { name: currentYear, data: current_year_payoffs }
        ];
        let chart;
        chart = Highcharts.chart('chart-container', {
          chart: {
            type: 'area'
          },
          title: {
            text: '',
            align: 'left'
          },
          xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          },
          yAxis: {
            title: {
              text: 'Amount'
            }
          },
          series: chartData
        });
      // }
    }

    $(window).on("load",function() {
      $('.work_logs').change(function() {
        var workLogValue = $(this).val();
        $.ajax({
          url: '/organizers/dashboard/daily_status_charts',
          method: 'GET',
          data: { work_log_value: workLogValue },
          success: function(response) {
            daliy_status_chart(response.done, response.hold, response.in_progress, response.backlogs, response.todo)
          }
        });
      });
      let daily_status = $('#current_day_daily_status').val()
      if(daily_status != undefined){
        const current_day_daily_status = JSON.parse(daily_status);
        daliy_status_chart(current_day_daily_status.done, current_day_daily_status.hold, current_day_daily_status.in_progress, current_day_daily_status.backlogs, current_day_daily_status.todo)
      }
    });

    $(document).ready(function() {
      $('.team_employee_task, .work_logs_section').change(function() {
        var workLogValue = $('.work_logs_section').val();
        var teamEmployeeTaskValue = $('.team_employee_task').val();
        var employeeId = $('#employee_id').val();
        $.ajax({
          url: '/employee/dashboard/employee_daily_status_charts',
          method: 'GET',
          data: { work_log_value: workLogValue, team_lead_or_employee: teamEmployeeTaskValue, employee_id: employeeId },
          success: function(response) {
            daliy_status_chart(response.done, response.hold, response.in_progress, response.backlogs, response.todo)
          }
        });
      });
      const employee_daily_status = $('#employee_daily_status').val();
      if (employee_daily_status) {
        const current_day_daily_status = JSON.parse(employee_daily_status);
        daliy_status_chart(current_day_daily_status.done, current_day_daily_status.hold, current_day_daily_status.in_progress, current_day_daily_status.backlogs, current_day_daily_status.todo)
      }
    });

    function daliy_status_chart(done, hold, in_progress, backlogs, todo) {
      // if (done === 0 && hold === 0 && in_progress === 0 && backlogs === 0 && todo === 0) {
      //   document.getElementById('daily_status_dashboard').innerHTML = '<div class="no-data" style="text-align: center;">No data available</div>';
      // } else {
        Highcharts.chart('daily_status_dashboard', {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
          },
          title: {
            text: '',
            align: 'left',
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          },
          accessibility: {
            point: {
              valueSuffix: '%',
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              },
            },
          },
          series: [{
            name: 'Status',
            colorByPoint: true,
            data: [{
              name: 'Back Logs',
              y: backlogs,
            }, {
              name: 'In-progress',
              y: in_progress,
            }, {
              name: 'Completed',
              y: done,
            }, {
              name: 'Todo',
              y: todo,
            }, {
              name: 'Hold',
              y: hold,
            }],
          }],
        });
      // }
    }

    $(document).ready(function() {
      $('#comp_off_request_section').change(function() {
        var compOff = $(this).val();
        console.log(compOff);
        $.ajax({
          url: '/employee/dashboard/comp_off_section',
          method: 'GET',
          data: { filter_type: compOff },
          success: function(response) {
            console.log(response)
            $('#total_compoff').text(response.total_compoff);
            $('#approved').text(response.approved);
            $('#pending').text(response.pending);
          }
        });
      });
    });

    $(document).ready(function() {
      $('#wfh_request_section').change(function() {
        var compOff = $(this).val();
        console.log(compOff);
        $.ajax({
          url: '/employee/dashboard/wfh_request_section',
          method: 'GET',
          data: { filter_type: compOff },
          success: function(response) {
            console.log(response)
            $('#total_wfh').text(response.total_wfh);
            $('#approved_wfh').text(response.approved);
            $('#pending_wfh').text(response.pending);
          }
        });
      });
    });

    $(document).ready(function() {
      $('#my_leave_filter').change(function() {
        var leave = $(this).val();
        console.log(leave);
        $.ajax({
          url: '/employee/dashboard/my_leave_section',
          method: 'GET',
          data: { filter_type: leave },
          success: function(response) {
            console.log(response)
            $('#total_leaves').text(response.leaves);
            $('#paid').text(response.paid);
            $('#unpaid').text(response.unpaid);
          }
        });
      });
    });

    $(document).ready(function() {
      $('#employee-time-filter').change(function() {
        var period = $(this).val();
        console.log(period);
        $.ajax({
          url: '/employee/dashboard/employee_section',
          method: 'GET',
          data: { period: period },
          success: function(response) {
            console.log(response)
            $('#total_employee').text(response.total_employees);
            $('#new_employee').text(response.new_employees);
            $('#left_employee').text(response.left_employees);
          }
        });
      });
    });

    $(document).ready(function() {
      $('#time-filter').change(function() {
        var period = $(this).val();
        console.log(period);
        $.ajax({
          url: '/organizers/dashboard/employee_section',
          method: 'GET',
          data: { period: period },
          success: function(response) {
            console.log(response)
            $('#total_employee').text(response.total_employees);
            $('#new_employee').text(response.new_employees);
            $('#left_employee').text(response.left_employees);
          }
        });
      });
    });

    $(document).ready(function() {
      $('#leave_request_section').change(function() {
        var leave = $(this).val();
        $.ajax({
          url: '/employee/dashboard/leave_request_section',
          method: 'GET',
          data: { filter_type: leave },
          success: function(response) {
            console.log(response)
            $('#total_leave').text(response.total_leave);
            $('#approved_leave').text(response.approved);
            $('#pending_leave').text(response.pending);
          }
        });
      });
    });

    

    $(document).ready(function() {
      $('#day').change(function() {
        var selected_day = $(this).val();
        $.ajax({
          url: '/organizers/dashboard/attendance_status',
          method: 'GET',
          data: { day: selected_day },
          success: function(response) {
            console.log(response)
            $('#attendance_date').text(response.attendance_date);
            $('#present_count').text(response.present_count);
            $('#leave_count').text(response.leave_count);
            $('#wfh_count').text(response.wfh_count);
            $('#absent_count').text(response.absent_count);
          }
        });
      });
    });

    $(document).ready(function() {
      $('#badge_dashboard').change(function() {
        var badge = $(this).val();
        console.log(badge)
        $.ajax({
          url: '/employee/dashboard/badge_section',
          method: 'GET',
          data: { filter_type: badge },
          success: function(response) {
            console.log(response)
            $('.scrollable-content').empty();
            var totalBadges = 0;
            $.each(response, function(key, value) {
              var row = $('<div class="row my-2"></div>');
              var col1 = $('<div class="col-md-5 text-end"></div>');
              col1.append($('<b id="title"></b>').text(key.charAt(0).toUpperCase() + key.slice(1)));
              var col2 = $('<div class="col-md-4 text-end"></div>');
              col2.append($('<b id="value"></b>').text(value));
              var border = $('<div class="border border-2 bg-info"></div>');
              row.append(col1, col2);
              $('.scrollable-content').append(row);
              $('.scrollable-content').append(border);
              totalBadges += parseInt(value);
            });
            $('#total_badges').text(totalBadges);
          }
        });
      });
    });

    $(document).ready(function() {
      $('#comp_off_request').change(function() {
        var compOff = $(this).val();
        console.log(compOff);
        $.ajax({
          url: '/organizers/dashboard/comp_off_section',
          method: 'GET',
          data: { filter_type: compOff },
          success: function(response) {
            console.log(response)
            $('#total_compoff').text(response.total_compoff);
            $('#approved').text(response.approved);
            $('#pending').text(response.pending);
          }
        });
      });
    });

    $(document).ready(function() {
      $('#badge_dashboard').change(function() {
        var badge = $(this).val();
        console.log(badge)
        $.ajax({
          url: '/organizers/dashboard/badge_section',
          method: 'GET',
          data: { filter_type: badge },
          success: function(response) {
            console.log(response)
            $('.scrollable-content').empty();
            var totalBadges = 0;
            $.each(response, function(key, value) {
              var row = $('<div class="row my-2"></div>');
              var col1 = $('<div class="col-md-5 text-end"></div>');
              col1.append($('<b id="title"></b>').text(key.charAt(0).toUpperCase() + key.slice(1)));
              var col2 = $('<div class="col-md-4 text-end"></div>');
              col2.append($('<b id="value"></b>').text(value));
              var border = $('<div class="border border-2 bg-info"></div>');
              row.append(col1, col2);
              $('.scrollable-content').append(row);
              $('.scrollable-content').append(border);
              totalBadges += parseInt(value);
            });
            $('#total_badges').text(totalBadges);
           }
        });
      });
    });


    $(document).ready(function() {
      $('#wfh_request').change(function() {
        var compOff = $(this).val();
        console.log(compOff);
        $.ajax({
          url: '/organizers/dashboard/wfh_request_section',
          method: 'GET',
          data: { filter_type: compOff },
          success: function(response) {
            console.log(response)
            $('#total_wfh').text(response.total_wfh);
            $('#approved_wfh').text(response.approved);
            $('#pending_wfh').text(response.pending);
          }
        });
      });
    });

    $(document).ready(function() {
      $('#leave_request').change(function() {
        var leave = $(this).val();
        $.ajax({
          url: '/organizers/dashboard/leave_request_section',
          method: 'GET',
          data: { filter_type: leave },
          success: function(response) {
            console.log(response)
            $('#total_leave').text(response.total_leave);
            $('#approved_leave').text(response.approved);
            $('#pending_leave').text(response.pending);
          }
        });
      });
    });

    $(window).on("load", function() {
      $('#hiring_dashboard').change(function() {
        var hiringValue = $(this).val();
        console.log(hiringValue)
        $.ajax({
          url: '/organizers/dashboard/hiring_charts',
          method: 'GET',
          data: { filter_type: hiringValue },
          success: function(response) {
            hiring_dashboard(response)
          }
        });
      });
      if($('#current_month_hirings').val() != undefined){
        const current_month_hirings = JSON.parse($('#current_month_hirings').val());
        hiring_dashboard(current_month_hirings)
      }
    });

    $(window).on("load", function() {
      $('#hiring_dashboard_section').change(function() {
        var hiringValue = $(this).val();
        console.log(hiringValue)
        $.ajax({
          url: '/employee/dashboard/hiring_section',
          method: 'GET',
          data: { filter_type: hiringValue },
          success: function(response) {
            hiring_dashboard(response)
          }
        });
      });
      if($('#current_month_hirings').val() != undefined){
        const current_month_hirings = JSON.parse($('#current_month_hirings').val());
        hiring_dashboard(current_month_hirings)
      }
    });

    

    function hiring_dashboard(objects) {
      // if (objects.applied === null && objects.offered === null && objects.rejected === null && objects.on_boarding === null) {
      //   document.getElementById('donut-chart-container').innerHTML = '<div class="no-data" style="text-align: center;">No data available</div>';
      // } else {
        var data = [
          { name: 'Applied', y: objects.applied, color: '#FDBE0F' },
          { name: 'Offered', y: objects.offered, color: '#A1CAF1' },
          { name: 'Rejected', y: objects.rejected, color: '#f2003c' },
          { name: 'On boarding', y: objects.on_boarding, color: '#50C878' }
        ];

        Highcharts.chart('donut-chart-container', {
          chart: {
            type: 'pie'
          },
          title: {
            text: ''
          },
          plotOptions: {
            pie: {
              innerSize: '50%',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.2f} %',
              },
              showInLegend: true,
              tooltip: {
                pointFormat: '<b>{point.percentage:.2f}%</b>'
              }
            }
          },
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            symbolWidth: 30,
            itemStyle: {
              fontWeight: 'normal'
            }
          },
          series: [{
            name: '',
            data: data
          }]
        });
      }
    }
  // }
}
