import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee--previous-job"
export default class extends Controller {
  connect() {
    customSelect2('#card_log_employee_id', true, 'All', true);
    if($('#employee_previous_job_section .nested-fields-prev-jobs').length == 0){
      $('#employee_previous_job_section .add_fields').click()
    }

    setTimeout(function() {
      loadDatePickerOnPrevJob();
    }, 100)
    $('#employee_previous_job_section .add_fields').click(function() {
      setTimeout(function() {
        loadDatePickerOnPrevJob();
      }, 100)
    })
    $(".join_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: "today",
    }).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        $('.seperation_date').datepicker('setStartDate', minDate);
      })
    $(".seperation_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: "today",
    })
    $('#job-form').validate();
    $('#previous-job').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('job_title')){
            $(input).rules( "add", {
              required: true,
              maxlength: 50,
              nameregex: true,
              messages: {
                required: "Job Title can't be blank",
                maxlength: 'Maximum 50 characters are allowed.',
                nameregex: 'Job Title only allow alphabets'
              }
            });
          }else if ($(input).attr('class').includes('department')) {
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Department can't be blank",
              }
            });
          }else if ($(input).attr('class').includes('city')) {
            $(input).rules( "add", {
              required: true,
              minlength: 2,
              maxlength: 20,
              nameregex: true,
              messages: {
                required: "City can't be blank",
                minlength: 'Please enter atleast 2 characters.',
                maxlength: 'Maximum 20 characters are allowed.',
                nameregex: 'Only allow letters'
              }
            });
          }else if ($(input).attr('class').includes('country')) {
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Country can't be blank",
              }
            });
          }else if ($(input).attr('class').includes('organization_name')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 50,
              nameregex: true,
              messages: {
                required: "Organization Name can't be blank",
                maxlength: 'Maximum 50 characters are allowed.',
                nameregex: 'Only allow letters'
              }
            });
          }else if ($(input).attr('class').includes('join_date')) {
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Join Date can't be blank",
              }
            });
          }else if ($(input).attr('class').includes('seperation_date')) {
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Separation Date can't be blank",
              }
            });
          }
        }
      })

      $(element).find('textarea').each(function(i, input) {
        if ($(input).attr('class').includes('reason_of_seperation')) {
          $(input).rules( "add", {
            required: true,
            minlength: 10,
            maxlength: 250,
            messages: {
              required: "Reason for Seperation can't be blank",
              minlength: 'Please enter atleast 10 characters.',
              maxlength: 'Maximum 250 characters are allowed.',
            }
          });
        }
      })

      $(element).find('select').each(function(i, input) {
        if($(input).attr('class').includes('country')){
          var id = '#' + $(input).attr('id')
          console.log(id)
          customSelect2(id, true);
          $(input).rules( "add", {
            required: true,
            messages: {
              required: "Country can't be blank",
            }
          });
        }
      })
    })

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');

  }
}
