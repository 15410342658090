import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-payoff"
export default class extends Controller {
  connect() {
    $(".filter-btn").click(function(){
      $(".organizer-payoff").toggleClass("order-enable");
      $(".organizer-payroll-withhold-report").toggleClass("order-enable");
    });
    customSelect2('#employee_ids', true, 'All Selected');
    customSelect2('#employee_id', true, 'Select Employee');
    customSelect2('#id', true, 'Select Department');
    customSelect2('#departmnet_name', true);
    customSelect2('#earning', true);
    customSelect2('#deduction', true);
    customSelect2('#total_loan_amount', true);
    customSelect2('#due_loan_amount', true);
    $("#new_employee_pay_off").validate({
      rules: {
        "employee_pay_off[salary_month]": {
          required: true,
        }
      },
      messages: {
        "employee_pay_off[salary_month]": {
          required:  'Please select payoff month',
        }
      }
    });

    $(".filter-btn").click(function(){
      $(".employee-pay-off").toggleClass("order-enable");
    });

    $('#employee_pay_off_salary_month').datepicker({
      format: 'mm/yyyy',
      minViewMode: 'months',
      autoclose: true
    });

    $('#salary_month').datepicker({
      format: 'mm/yyyy',
      minViewMode: 'months',
      autoclose: true
    });

    $('#publish_at_date').datepicker({
      format: 'dd M yyyy',
      endDate: '+0d',
      autoclose: true
    })

    $(document).ready(function() {
      $('.payoff-checkbox').change(function() {
        var selectedIds = [];

      $('.payoff-checkbox:checked').each(function() {
        selectedIds.push($(this).data('id'));
      });
      $('#payoff_ids').val(selectedIds);
      });
    });

    $(document).ready(function() {
      const selectAllCheckbox = $("#select-all-checkbox");
      const rowCheckboxes = $(".payoff-checkbox");
      const selectedIds = [];

      selectAllCheckbox.on("change", function() {
        const isChecked = selectAllCheckbox.prop("checked");

        rowCheckboxes.each(function() {
          $(this).prop("checked", isChecked);
        });

        $('.payoff-checkbox:checked').each(function() {
          selectedIds.push($(this).data('id'));
        });

        $('#payoff_ids').val(selectedIds.join(","));

        console.log(selectedIds);
      });
    });
  }
}
