import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="appraisal-meeting"
export default class extends Controller {
  connect() {
    $(".AppraisalMeetingForm").validate({
      rules: {
        "appraisal_meeting[meeting_date]": {
          required: true,
        },
        "appraisal_meeting[meeting_time]": {
          required: true,
        },
        "appraisal_meeting[team_lead_members][]": {
          required: true,
        },
        "appraisal_meeting[meeting_members][]": {
          required: true,
        }
      },
      messages: {
        "appraisal_meeting[meeting_date]": {
          required: "Please select meeting date."
        },
        "appraisal_meeting[meeting_time]": {
          required: "Please select meeting date."
        },
        "appraisal_meeting[team_lead_members][]": {
          required: "Please select team lead."
        },
        "appraisal_meeting[meeting_members][]": {
          required: "Please select team lead."
        }
      }
    });
    customSelect2('#appraisal_meeting_meeting_members', true);
    customSelect2('#appraisal_meeting_team_lead_members', true);
    $('#appraisal_meeting_meeting_date').datepicker({
      format: 'dd/mm/yyyy',
      startDate: '+0d',
      autoclose: true
    })
  }
}
