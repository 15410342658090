import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="template"
export default class extends Controller {
  connect() {
    
    $(".filter-btn").click(function(){
      $(".organizer-template").toggleClass("order-enable");
    });

    customSelect2('#org_setting_referal_policy_id', true);
    validatorBasic();
    $('input').on('keydown', function() {
      $('.errors').text('')
    });
    $('.templateFrom').validate({
      rules: {
        'template[title]':{
          required:true,
          minlength: 3,
          maxlength: 40,
          titleRegex: true,
        },
        'template[template_category_id]':{
          required:true,
        },
      },
       messages: {
        'template[title]':{
          required: 'Please enter titles.',
          maxlength: 'Sorry, title must be between 3 and 40 characters long.',
          minlength: 'Sorry, title must be between 3 and 40 characters long.',
        },
        'template[template_category_id]':{
          required: 'Please select category',
        },
      }
    })
    $.validator.addMethod("titleRegex", function (value, element) {
      return this.optional(element) || /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(value)
    }, "Please Enter Valid title...");
  }
}
