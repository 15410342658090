import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard--revenue"
export default class extends Controller {
    connect() {
        // customSelect2('#revenue', true, 'Year');
        // Initialize the chart with the data for the current year on page load
        const currentYear = new Date().getFullYear();
        loadChartData(currentYear);

        $(document).ready(function() {
            $('#revenue').change(function() {
                var Revenue = $(this).val();
                loadChartData(Revenue);
            });
        });

        function loadChartData(year) {
            $.ajax({
                url: '/organizers/dashboard/revenue',
                method: 'GET',
                data: { year: year },
                success: function(response) {
                    var revenue = response.revenue;
                    var expenditure = response.expenditure;
                    myChart(revenue, expenditure);
                },
                error: function(error) {
                    console.error('Error:', error);
                }
            });
        }
        function myChart(revenue, expenditure) {
            console.log(revenue, expenditure);

            var monthNames = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];

            var categories = monthNames.slice(0, revenue.length);

            Highcharts.chart('revenue_dashboard', {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                },
                xAxis: {
                    categories: categories,
                    accessibility: {
                        rangeDescription: monthNames
                    }
                },
                yAxis: {
                    title: {
                        text: 'Amount'
                    }
                },
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        }
                    }
                },
                series: [{
                    name: 'Income',
                    data: revenue,
                    color: '#61e373',
                }, {
                    name: 'Expenditure',
                    data: expenditure,
                    color: '#f86934'
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }
            });
        }
    }
}
