import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash-message"
export default class extends Controller {
  connect() {
    setTimeout(function() {
      $("#flash-message").remove();
    }, 5000);
  }
}
