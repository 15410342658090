import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee--bank-detail"
export default class extends Controller {
  connect() {
    if($('#employee_bank_detail_section .nested-fields-bank-details').length == 0){
      $('#employee_bank_detail_section .add_fields').click()
    }

    setTimeout(function() {
      loadDatePickerOnPrevJob();
    }, 100)
    $('#employee_bank_detail_section .add_fields').click(function() {
      setTimeout(function() {
        loadDatePickerOnPrevJob();
      }, 100)
    })

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');

    $.validator.addMethod('digitregex', function(value, element){
      return this.optional(element) || /\d\b/g.test(value);
    },'Contains digits only');
  }
}
