import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="role"
export default class extends Controller {
  connect() {
    $(".filter-btn").click(function(){
      $(".organizer-role").toggleClass("order-enable");
    });

    $('#roleForm').validate({
      rules: {
        "role[name]": {
          required: true,
          minlength: 2,
          maxlength: 20,
          nameregex: true
        }
      },
      messages: {
        "role[name]": {
          required: 'This is a required field.',
          minlength: 'Must be 2-20 characters long',
          maxlength: 'Must be 2-20 characters long'
        }
      }
    })

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    },'Contains alphabets only');

    this.employeePermission()
  }

  employeePermission() {
    if ($("#role_employee_feature").is(":checked")) {
      $(".employee-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".employee-permission label").removeClass('disable-text')
    } else {
      $(".employee-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".employee-permission label").addClass('disable-text')
    }

    if ($("#role_card_show").is(":checked")) {
      $(".card-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".card-permission label").removeClass('disable-text')
    } else {
      $(".card-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".card-permission label").addClass('disable-text')
    }

    if ($("#role_assets_show").is(":checked")) {
      $(".asset-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".asset-permission label").removeClass('disable-text')
    } else {
      $(".asset-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".asset-permission label").addClass('disable-text')
    }

    if ($("#role_ticket_permission").is(":checked")) {
      $(".ticket-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".ticket-permission label").removeClass('disable-text')
    } else {
      $(".ticket-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".ticket-permission label").addClass('disable-text')
    }

    if ($("#role_role_feature").is(":checked")) {
      $(".role-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".role-permission label").removeClass('disable-text')
    } else {
      $(".role-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".role-permission label").addClass('disable-text')
    }

    if ($("#role_project_feature").is(":checked")) {
      $(".project-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".project-permission label").removeClass('disable-text')
    } else {
      $(".project-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".project-permission label").addClass('disable-text')
    }

    if ($("#role_benefit_section").is(":checked")) {
      $(".benefit-comp-off input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".benefit-comp-off label").removeClass('disable-text')
    } else {
      $(".benefit-comp-off input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".benefit-comp-off label").addClass('disable-text')
    }

    if ($("#role_leave_section").is(":checked")) {
      $(".leave-section input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".leave-section label").removeClass('disable-text')
    } else {
      $(".leave-section input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".leave-section label").addClass('disable-text')
    }

    if ($("#role_holiday_section").is(":checked")) {
      $(".holiday-section input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".holiday-section label").removeClass('disable-text')
    } else {
      $(".holiday-section input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".holiday-section label").addClass('disable-text')
    }

    if ($("#role_policy_section").is(":checked")) {
      $(".policy-section input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".policy-section label").removeClass('disable-text')
    } else {
      $(".policy-section input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".policy-section label").addClass('disable-text')
    }

    if ($("#dashboard_section").is(":checked")) {
      $(".dashboard-section-show input[type=checkbox], .dashboard-section-disabled input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".dashboard-section-show label, .dashboard-section-disabled label").removeClass('disable-text')
    } else {
      $(".dashboard-section-show input[type=checkbox], .dashboard-section-disabled input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".dashboard-section-show label, .dashboard-section-disabled label").addClass('disable-text')
    }

    if ($("#employee_permission_project_edit").is(":checked")) {
      $(".emp-project-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".emp-project-permission label").removeClass('disable-text')
    } else {
      $(".emp-project-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".emp-project-permission label").addClass('disable-text')
    }

    if ($("#employee_permission_request_feature").is(":checked")) {
      $(".request-section-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".request-section-permission label").removeClass('disable-text')
    } else {
      $(".request-section-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".request-section-permission label").addClass('disable-text')
    }

    if ($("#employee_permission_meeting_feature").is(":checked")) {
      $(".meeting-section-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".meeting-section-permission label").removeClass('disable-text')
    } else {
      $(".meeting-section-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".meeting-section-permission label").addClass('disable-text')
    }                  

    if ($("#role_reports_feature").is(":checked")) {
      $(".report-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".report-permission label").removeClass('disable-text')
    } else {
      $(".report-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".report-permission label").addClass('disable-text')
    }

    if ($("#role_meetings_feature").is(":checked")) {
      $(".meeting-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".meeting-permission label").removeClass('disable-text')
    } else {
      $(".meeting-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".meeting-permission label").addClass('disable-text')
    }

    if ($("#role_invoices_feature").is(":checked")) {
      $(".invoice-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".invoice-permission label").removeClass('disable-text')
    } else {
      $(".invoice-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".invoice-permission label").addClass('disable-text')
    }

    if ($("#role_hirings_feature").is(":checked")) {
      $(".hiring-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".hiring-permission label").removeClass('disable-text')
    } else {
      $(".hiring-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".hiring-permission label").addClass('disable-text')
    }

    if ($("#role_appraisals_feature").is(":checked")) {
      $(".appraisal-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".appraisal-permission label").removeClass('disable-text')
    } else {
      $(".appraisal-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".appraisal-permission label").addClass('disable-text')
    }

    if ($("#role_benefits_feature").is(":checked")) {
      $(".benefit-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".benefit-permission label").removeClass('disable-text')
    } else {
      $(".benefit-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".benefit-permission label").addClass('disable-text')
    }

    if ($("#role_payroll_feature").is(":checked")) {
      $(".payroll-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".payroll-permission label").removeClass('disable-text')
    } else {
      $(".payroll-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".payroll-permission label").addClass('disable-text')
    }

    if ($("#role_reimbursement_feature").is(":checked")) {
      $(".reimbursement-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".reimbursement-permission label").removeClass('disable-text')
    } else {
      $(".reimbursement-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".reimbursement-permission label").addClass('disable-text')
    }

    if ($("#role_announcement_feature").is(":checked")) {
      $(".announcement-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".announcement-permission label").removeClass('disable-text')
    } else {
      $(".announcement-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".announcement-permission label").addClass('disable-text')
    }

    if ($("#role_attendance_feature").is(":checked")) {
      $(".attendance-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".attendance-permission label").removeClass('disable-text')
    } else {
      $(".attendance-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".attendance-permission label").addClass('disable-text')
    }
    
    if ($("#role_card_logs_permission").is(":checked")) {
      $(".cardlog-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".cardlog-permission label").removeClass('disable-text')
    } else {
      $(".cardlog-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".cardlog-permission label").addClass('disable-text')
    }

    if ($("#role_request_feature").is(":checked")) {
      $(".request-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".request-permission label").removeClass('disable-text')
    } else {
      $(".request-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".request-permission label").addClass('disable-text')
    }

    if ($("#role_separation_termination_feature").is(":checked")) {
      $(".separation_termination-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".separation_termination-permission label").removeClass('disable-text')
    } else {
      $(".separation_termination-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".separation_termination-permission label").addClass('disable-text')
    }

    if ($("#role_refferal_feature").is(":checked")) {
      $(".refferal-permission input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".refferal-permission label").removeClass('disable-text')
    } else {
      $(".refferal-permission input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".refferal-permission label").addClass('disable-text')
    }

    if ($("#role_configuration_permission").is(":checked")) {
      $(".configuration-permission-setting input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".configuration-permission-setting label").removeClass('disable-text')
    } else {
      $(".configuration-permission-setting input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".configuration-permission-setting label").addClass('disable-text')
    }

    if ($("#role_general_permission").is(":checked")) {
      $(".general-permission-setting input[type=checkbox]").each(function(index) {
        $(this).removeAttr('disabled')
        $(this).removeClass('disable-text')
      });
      $(".general-permission-setting label").removeClass('disable-text')
    } else {
      $(".general-permission-setting input[type=checkbox]").each(function(index) {
        $(this).prop('disabled', 'disabled')
        $(this).prop('checked', false)
        $(this).addClass('disable-text')
      });
      $(".general-permission-setting label").addClass('disable-text')
    }

  }
}
