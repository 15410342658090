import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="department"
export default class extends Controller {
  connect() {
    $(".filter-btn").click(function(){
      $(".organizer-department").toggleClass("order-enable");
    });
    validatorBasic();
    $('input').on('keyup', function() {
      $('.errors').text('')
    });
    $('.DepartmentForm').validate({
      rules: {
        "department[name]": {
          required: true,
          minlength: 2,
          maxlength: 50,
          nameregex: true
        }
      },
      messages: {
        "department[name]": {
          required: 'Please enter department name.',
          minlength: 'Must be 2-50 characters long',
          maxlength: 'Must be 2-50 characters long',
          nameregex: 'Contains alphabets only'
        }
      }});
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');

    // $('#designation_start_date').datepicker({
    //   format: 'dd/mm/yyyy',
    //   endDate: '+0d',
    //   autoclose: true
    // })

    // $('#designation_end_date').datepicker({
    //   format: 'dd/mm/yyyy',
    //   endDate: '+0d',
    //   autoclose: true
    // })
  }
}
