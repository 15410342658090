import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="designation"
export default class extends Controller {
  connect() {

    $(".filter-btn").click(function(){
      $(".organizer-designation").toggleClass("order-enable");
    });    
    $(document).on("click", "#filter-button", function () {
      $('#filter-section').toggleClass('d-none')
    });
    validatorBasic();
    $('input').on('keyup', function() {
      $('.errors').text('')
    });
    $('.designationFrom').validate({
      rules: {
        "designation[name]": {
          required: true,
          minlength: 2,
          maxlength: 50,
          nameregex: true
        }
      },
      messages: {
        "designation[name]": {
          required: 'Designation name is required.',
          minlength: 'Must be 2-50 characters long',
          maxlength: 'Must be 2-50 characters long',
          nameregex: 'Contains alphabets only'
        }
      }});
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z!@#$%^&*()_/ ]+$/.test(value);
    },'Contains alphabets only');

    $('#designation_start_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    })

    $('#designation_end_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    })

    var initialFromDate = $('#designation_start_date').datepicker('getDate');
    if (initialFromDate != null){
      $('#designation_end_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#designation_end_date').datepicker('getDate');
    if (initialToDate != null){
      $('#designation_start_date').datepicker('setEndDate', initialToDate);
    }
  }
}
