import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="appraisal-setting"
export default class extends Controller {
  connect() {
    $(".AppraisalSettingForm").validate({
      rules: {
        "appraisal_setting[notification_alert_days]": {
          required: true,
        },
        "appraisal_setting[template_form_alert_days]": {
          required: true,
        }
      },
      messages: {
        "appraisal_setting[notification_alert_days]": {
          required: "Please select notification reminder days."
        },
        "appraisal_setting[template_form_alert_days]": {
          required: "Please select template form reminder days."
        }
      }
    });
    customSelect2('#appraisal_setting_notification_alert_days', true);
    customSelect2('#appraisal_setting_template_form_alert_days', true);
  }
}
