import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="setup-organization--master"
export default class extends Controller {
  connect() {
    $(".master-data-select").select2({
      tags: true
    });

    $('select').on('change', function() {
      $(this).valid();
    })

    $('#master-data-setup').validate({
      rules: {
        'department[]': {
          required: true,
          minTags: 5 // Set the minimum number of selected tags
        },
        'designation[]': {
          required: true,
          minTags: 5 // Set the minimum number of selected tags
        },
        'grade[]': {
          required: true,
          minTags: 5 // Set the minimum number of selected tags
        },
        'employment_type[]': {
          required: true,
          minTags: 2 // Set the minimum number of selected tags
        }
      },
      messages: {
        'your_model[tags][]': {
          required: "Please select at least 5 tags.",
          minlength: "Please select at least 5 tags."
        },
        'department[]': {
          required: 'Please select at least 5 departments',
          minTags: 'Please select at least 5 departments' // Set the minimum number of selected tags
        },
        'designation[]': {
          required: 'Please select at least 5 designations',
          minTags: 'Please select at least 5 designations' // Set the minimum number of selected tags
        },
        'grade[]': {
          required: 'Please select at least 5 grades',
          minTags: 'Please select at least 5 grades' // Set the minimum number of selected tags
        },
        'employment_type[]': {
          required: 'Please select at least 2 employment types',
          minTags: 'Please select at least 2 employment types' // Set the minimum number of selected tags
        }
      },
    })
    $.validator.addMethod("minTags", function(value, element, param) {
      return $(element).val() && $(element).val().length >= param;
    });



    $('.master-data-select').on('select2:select', function (e) {
      let newTag = e.params.data.text;
      // Regex to check if the new tag contains only alphabets (a-z, A-Z)
      let regex = /^[A-Za-z ]+$/;
      if (!regex.test(newTag)) {
        // Remove the invalid tag
        let $select2 = $(this);
        let values = $select2.val();
        values.pop(); // Remove the last (invalid) tag
        $select2.val(values).trigger('change');
      }
    });
  }
}
