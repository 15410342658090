import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="assets-issues"
export default class extends Controller {
  connect() {

    $(".comment_new_form_id").validate({
      rules: {
        "comment[comment_text]": {
          required: true,
        },
      },
      messages: {
        "comment[comment_text]": {
          required: 'Please enter comment.',
        },
      }
    });    

    $(".assets_issue_form").validate({
      rules: {
        "ticket[priority]": {
          required: true,
        },
        "ticket[description]": {
          required: true,
          minlength: 10
        }

      },
      messages: {
        "ticket[priority]": {
          required: 'Please select priority.',
        },
        "ticket[description]": {
          required: 'Please enter details.',
          minlength: 'Please enter atleast 10 character'
        },
      }
    });

    $(".AssetsCategoryForm").validate({
      rules: {
        "asset_category[name]": {
          required: true,
          minlength: 3,
          maxlength: 50
        }
      },
      messages: {
        "asset_category[name]": {
          required: 'Please enter name.',
          minlength: 'Please enter atleast 3 character',
          maxlength: 'Only 50 digits are allowed'
        }
      }
    })
  }
}
